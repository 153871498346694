import React, { useState, useEffect, useContext ,useMemo,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Tooltip } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import DeleteIcon from '@mui/icons-material/Clear';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles-custom.css'; //referenced after core styles for ag-grid

function RecipientListEdit() {
  const { baseURL } = useContext(AuthContext);
  const history = useHistory();
  const [internalError, setInternalError] = useState('');
  const [aryRecipient, setRecipientArray] = useState(undefined);
  const [aryRecipientFiltered, setRecipientArrayFiltered] = useState(undefined);
  const [isArrayInitialized, setIsArrayInitialized] = useState(false);
  const [query, setQuery] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  //vars to handle delete
  const [deleteSK, setDeleteSK] = useState(undefined);
  const [deleteText, setDeleteText] = useState(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDataReset, setIsDataReset] = useState(false);

  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  var bIsInitialized = false;
  //-------------------------------------------------------------  
  // user grid variables
  const gridRef = useRef();

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const columnDefs = [
    {
      headerName: '', field: 'sk', colId: 'deleteCol', flex: 1, cellRenderer: params => {
        return <Tooltip title="delete the recipient"><DeleteIcon sx={{ color: 'var(--colorDisapprove)', padding: '5px' }} /></Tooltip>;
      }
    },
    { headerName: 'Name', field: 'label', flex: 5 },
    { headerName: 'Email', field: 'email', flex: 5 }
  ];

  const defaultColDef = {
    sortable: true,
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressMovable : true
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const doCellClicked = (params) => {
    //handle click in data grid
    //check to see if it's in the delete column, if so handle delete
    //otherwise go to editing the user
    if (params.colDef.colId === "deleteCol") {
      handleItemDelete(params.data.value, params.data.label);
    } else {
      handleItemClick(params.data.value, params.data.label,params.data.email);
    }
  };
  //-------------------------------------------------------------
  // handle list selection

  const handleItemClick = (sk, fullName ,email) => {
    history.push(`/recipientAdd/${sk}/${fullName}/${email}`);
  };

  //-------------------------------------------------------------
  // functions to handle delete functionality

  const handleItemDelete = (itemSK, itemTitle) => {
    setDeleteSK(itemSK);
    setDeleteText(itemTitle)
    setOpenDeleteDialog(true);
  };

 const handleDialogCancel = () => {
  setDeleteSK(undefined);
  setDeleteText(undefined)
  setOpenDeleteDialog(false);
}

  const handleDialogOK = async () => {
    // close the dialog
    setOpenDeleteDialog(false);

    if (deleteSK === undefined) {
      setErrorMessage("the sk to delete is undefined");
    } else {
      //save to database
      const formData = new FormData();
      formData.append('addressbookSK', deleteSK);
      let objData = await postData(baseURL + 'addressbookDelete.php', formData);
      if (objData.theError === "") {
        // refresh topic list
        setIsDataReset(true);
      } else {
        setErrorMessage(objData.theError);
      }

    }
  }
  
useEffect(() => {
  if (isDataReset){
    setRecipientArray(undefined);
    setIsArrayInitialized(false);
    setRetryCount(retryCount - 1);
  }// eslint-disable-next-line
} ,[isDataReset])
  
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleErrorDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //-------------------------------------------------------------
  // get the topic list from the server

  const getIssueList = async () => {
    if (isArrayInitialized === false) {
      const formData = new FormData();
      var objEntity = await postData(baseURL + 'addressbookSelect.php', formData);
      setInternalError(objEntity.theError);
      if (bIsInitialized === false) {
        if ((aryRecipient === undefined) && (objEntity.theData !== null)) {
          setRecipientArray(objEntity.theData);
          setRecipientArrayFiltered(objEntity.theData);
          setIsArrayInitialized(true);
          setIsDataReset(false);
        };
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 
    }
  }
  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [retryCount]);


  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [isArrayInitialized]);

  //-------------------------------------------------------------

  useEffect(() => {
    // filter the list when query changes
    if (aryRecipient === undefined) {
      return undefined
    } else {
      setRecipientArrayFiltered(aryRecipient.filter((row) => row.label.toLowerCase().includes(query.toLocaleLowerCase())));
    }
  }, [query, aryRecipient])
  //-------------------------------------------------------------

  return (
    <>
      <div className="searchable-list">
        <h3>Select a recipient to edit</h3>
        <br />
        <div align="left" style={{ paddingBottom: '20px',display: "flex", flexDirection: "row"  }}>
          <input
            type="text"
            placeholder="Search..."
            id="searchbox"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {(aryRecipientFiltered === undefined) && <Loadingalert />}
        <div id="gridContainer" style={{ height: '70vh', width: '100%', textAlign: 'left' }}>
          <div style={gridStyle} className="ag-theme-alpine">
            <AgGridReact
              ref={gridRef}
              rowData={aryRecipientFiltered}
              popupParent={popupParent}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onCellClicked={doCellClicked}
            />
          </div>
        </div>        
        {(internalError !== "" && retryCount > 2) && <div>Error:{internalError}</div>}
      </div>
      <Dialog
        open={openDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Recipient Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete '{deleteText}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancel</Button>
          <Button onClick={handleDialogOK}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Recipient Deletion Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RecipientListEdit;