import { React, useState, useContext, useEffect, useRef } from 'react';
import { Formik, Form, Field } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Paper, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import postData from './postData';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from './AuthContext';
import { useParams, useHistory } from 'react-router-dom';
import Loadingalert from './LoadingAlert';
import thumbsUpImage from './images/thumbs-up.gif';
import ImageModal from './imageModal';


const IssueCommentForm = () => {

  //form variables
  const { baseURL } = useContext(AuthContext);
  const editorRef = useRef(null); //reference to MCE editor
  const history = useHistory();

  const { sk } = useParams();  //get parameter from router
  const [isInitialized, setIsInitialized] = useState(false);
  const [submitting, setSubmitting] = useState(false);


  //issue values
  const [fullName, setFullName] = useState("");
  const [neighborhoodName, setNeighborhoodName] = useState("");
  const [isCitywide, setIsCityWide] = useState(false);
  const [neighborhoodSupport, setNeighborhoodSupport] = useState(null);
  const [body, setBody] = useState("");
  const [rsIssue, setrsIssue] = useState(undefined);
  const [imageFilename, setImageFileName] = useState(null);

  // comment values
  const [fullNameComment, setFullNameComment] = useState("");
  const [emailComment, setEmailComment] = useState("");
  const [residency, setResidency] = useState(null);
  const [opinion, setOpinion] = useState(null);
  const [approveLabel, setApproveLabel] = useState("");
  const [disaproveLabel , setDisapproveLabel] = useState("");

  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  //---------------------------------------------------
  // initialize the issue values

  const retrieveIssueInfo = async () => {
    const formData = new FormData();
    formData.append('issueSK', sk);
    formData.append('isActive', 1);
    formData.append('neighborhoodSK', null);
    var objData = await postData(baseURL + 'issueSelect.php', formData);
    if (objData.theError === "") {
      setrsIssue(objData.theData);
    } else {
      setErrorMessage(objData.theError);
    }
  }
  
  useEffect(() => {
    if (isInitialized === false) {
      if (sk === '0') {
        setFullName('');
        setIsCityWide(0);
        setBody('');
        setImageFileName('');
        setIsInitialized(true);
      } else {
        retrieveIssueInfo();
      }
    }
// eslint-disable-next-line     
  },[isInitialized,sk])

  useEffect(() => {
    if (rsIssue !== undefined) {
      setFullName(rsIssue[0].fullName);
      setNeighborhoodName(rsIssue[0].neighborhoodName);
      setIsCityWide(rsIssue[0].isCityWide);
      setBody(rsIssue[0].body);
      if (rsIssue[0].imageFileName !== null) {
        setImageFileName(baseURL + "upload/" + rsIssue[0].imageFileName);
      }
      if (rsIssue[0].approveDescription !== "") {
      setApproveLabel(" (" + rsIssue[0].approveDescription + ")");
      }
      if (rsIssue[0].disapproveDescription !== "") {
        setDisapproveLabel(" (" + rsIssue[0].disapproveDescription + ")");
      }

      if (rsIssue[0].neighborhoodSupport === null){
        setNeighborhoodSupport(null);
      } else {
        setNeighborhoodSupport(rsIssue[0].neighborhoodSupport.slice(0,rsIssue[0].neighborhoodSupport.length - 2));
      }

      setIsInitialized(true);
    }
  }, [rsIssue, baseURL])


  //---------------------------------------------------

  const saveComment = async () => {
    let isComplete = true;

    // perform additional validity checkings
    if (residency === null) {
      isComplete = false;
      setErrorMessage("Please choose your residency status.");
    }

    if (opinion === null) {
      isComplete = false;
      setErrorMessage("Please select one of the opinion options.");
    }

    // Regular expression to validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(emailComment);
    if (isValidEmail === false) {
      isComplete = false;
      setErrorMessage("The email address does not appear to be valid.");
    }

    if (isComplete) {
      setSubmitting(true);
      let bodyComment = "";
      if (editorRef.current) {
        bodyComment = editorRef.current.getContent();
      }
      //save to database
      const formData = new FormData();
      formData.append('issueSK', sk);
      formData.append('fullName', fullNameComment);
      formData.append('email', emailComment);
      formData.append('residency', residency);
      formData.append('opinion', opinion);
      formData.append('body', bodyComment);
      let objData = await postData(baseURL + 'issueCommentAddEdit.php', formData);
      if (objData.theError === "") {
        //congratulate user
        setOpenSuccessDialog(true);
      } else {
        setErrorMessage(objData.theError);
        setSubmitting(true);
      }
    }  //isComplete
  };


  //---------------------------------------------------
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };

  const handleSuccessDialogOK = () => {
       //go back to home screen
       history.push(`/`);
  };
  //---------------------------------------------------
  return (<>
    {(isInitialized === false) && <Loadingalert />}
    <div>
      <h3  id="header" dangerouslySetInnerHTML={{ __html: fullName }} />
      <figure>
        {/*
 {(imageFilename !== null) && <img src={imageFilename} alt="....Nothing..." style={{ maxWidth: '40%', border: '2px solid #2b9ed4' }} />}

  */}
        {(imageFilename !== null) && <ImageModal imageUrl={imageFilename} />}
               <figcaption style={{ fontStyle: "italic",marginBottom:'10px' }} >{(neighborhoodName !== null) && <>Created by {neighborhoodName}</>}
          {(isCitywide === '1') && <> (citywide)</>}
          {(neighborhoodSupport !== null) && <> & supported by {neighborhoodSupport}</>}
        </figcaption>
      </figure>
      <div id="body" style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: body }} />
      <Paper elevation={10}>
        <h3 style={{ paddingTop: '15px' }}>What do you think?</h3>
        <Formik
          initialValues={{
            fullName: '',
            email: '',
          }}
          onSubmit={async (values) => {
            saveComment();
          }}
        >
          <Form style={{ textAlign: "left", padding: 10 }}>

            <label htmlFor="fullName">Name</label>
            <Field
              name="fullName"
              type="text"
              value={fullNameComment}
              onChange={(e) => setFullNameComment(e.target.value)}
              style={{ width: "50%" }}
              required
            />

            <label htmlFor="email">Email</label>
            <Field
              name="email"
              type="text"
              value={emailComment}
              onChange={(e) => setEmailComment(e.target.value)}
              style={{ width: "50%" }}
              required
            />

            <label htmlFor="residency" sx={{ padding: '0', border: '1pt solid' }}>Residency</label>
            <RadioGroup
              name="residency"
              value={residency}
              onChange={(event) => setResidency(event.target.value)}
              row
              sx={{ paddingTop: '5px', paddingBottom: '5px' }}
              required
            >
              {(neighborhoodName !== null) && <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="2" value="2" control={<Radio sx={{ padding: '1px' }} />} label={neighborhoodName + " resident"} />}
              <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="1" value="1" control={<Radio sx={{ padding: '1px' }} />} label="City resident" />
              <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="0" value="0" control={<Radio sx={{ padding: '1px' }} />} label="Outside City" />
            </RadioGroup>

            <label htmlFor="opinion" sx={{ padding: '0', border: '1pt solid' }}>Opinion</label>
            <RadioGroup
              name="opinion"
              value={opinion}
              onChange={(event) => setOpinion(event.target.value)}
              row
              sx={{ paddingTop: '5px', paddingBottom: '15px' }}
              required
            >
              <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="1" value="1" control={<Radio sx={{ padding: '1px' }} />} 
                                  label={       
                                     <>
                                    <Typography variant="body" component="span">
                                      Approve
                                    </Typography>
                                    <Typography variant="caption" component="span">
                                      {approveLabel}
                                    </Typography>
                                  </>} />
              <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="0" value="0" control={<Radio sx={{ padding: '1px' }} />} label="Neutral" />
              <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="-1" value="-1" control={<Radio sx={{ padding: '1px' }} />} 
                                  label={       
                                     <>
                                    <Typography variant="body" component="span">
                                      Oppose
                                    </Typography>
                                    <Typography variant="caption" component="span">
                                      {disaproveLabel}
                                    </Typography>
                                  </>} />
            </RadioGroup>
            <div style={{ paddingTop: '10px' }}>
              <Editor
                apiKey='o0ciiwymd8qsbwojphyi3fsuk7s3smpby7wouwbpat98a246'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=""
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code',
                  ],
                  toolbar: 'undo redo  | ' +
                    'bold italic link | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | fontfamily fontsize | ',
                  content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px } " +
                                 ".mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before { " +
                                 " font-style: italic; " +
                                  "font-size: small; " +
                                  "color:#cfcfcf;}",
                  placeholder: "write a comment to add further impact...",
                  contextmenu: 'undo redo | cut copy paste pastetex image table | link' 
                }}
              /></div>
            <div className='formButtonContainer'>
              <LoadingButton type="submit" loading={submitting} disabled={submitting} variant='contained'>Submit</LoadingButton>
            </div>
          </Form>
        </Formik></Paper>
    </div>
    <Dialog
      open={openWarningDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Comment System Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogOK}>Ok</Button>
      </DialogActions>
    </Dialog>
    <Dialog
      open={openSuccessDialog}
      aria-labelledby="success-dialog-title"
      aria-describedby="success-dialog-description"
    >
      <DialogTitle id="success-dialog-title">
        Congratulutions
      </DialogTitle>
      <DialogContent><div style={{textAlign:"center"}}>
        <img src={thumbsUpImage} 
             alt="..thumbs up.." 
             style={{ maxWidth: '20%', height: 'auto' }}
        /></div>
        <DialogContentText id="success-dialog-description">
          Your thoughts have been saved and your voice will be heard.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSuccessDialogOK}>Continue</Button>
      </DialogActions>
    </Dialog>
  </>

  );
};

export default IssueCommentForm;