import { React, useState, useContext ,useEffect} from 'react';
import { useHistory } from 'react-router-dom';
import { Stepper, Step, StepLabel, StepContent, Button, Typography } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import IssueAddFormStep1 from './issueAddFormStep1';
import IssueAddFormStep2 from './issueAddFormStep2';
import IssueAddFormStep3 from './issueAddFormStep3';
import { useParams } from 'react-router-dom';
import Loadingalert from './LoadingAlert';


const IssueAddForm = () => {

  //form variables
  const {baseURL} = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ['Step 1:Basic Information', 'Step 2:Select an Image', 'Step 3:Choose Recipients']; 
  const history = useHistory();

  const {sk ,title} = useParams();  //get parameter from router
  const [issueSK, setIssueSK] = useState();
  const [isInitialized, setIsInitialized] = useState(false);
  

  //step 1 values
  const [fullName, setFullName] = useState("");
  const [neighborhoodSK, setNeighborhoodSK] = useState("");
  const [isCitywide, setIsCityWide] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [isPrivate, setIsPrivate] = useState(false);
  const [body, setBody] = useState("");
  const [rsIssue, setrsIssue] = useState(undefined);
  const [approveDescription, setApproveDescription] = useState("");
  const [disapproveDescription, setDisapproveDescription] = useState("");
  const [dateEnd, setDateEnd] = useState("");

  //step 2 values
  const [imageFilename, setImageFileName] = useState(null);

  //step 3 values
  const [Recipient,setRecipient] = useState();
  
  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');


  //---------------------------------------------------
  // initialize the issue values

  const retrieveIssueInfo = async () => {
    const formData = new FormData();
    formData.append('issueSK', sk);
    formData.append('isActive', 1);
    formData.append('neighborhoodSK', null);
    var objData = await postData(baseURL + 'issueSelect.php', formData);
    if (objData.theError === "") {
      setrsIssue(objData.theData);
      setRecipient(objData.theData2);
    } else {
      setErrorMessage(objData.theError);
    }
  }

// eslint-disable-next-line 
  useEffect(() => {
    if (isInitialized === false) {
      if (sk === '0') {
        setFullName('');
        setNeighborhoodSK(0);
        setIsCityWide(false);
        setBody('');
        setImageFileName('');
        setIsActive(true);
        setIsPrivate(false);
        setIsInitialized(true);
        setApproveDescription('');
        setDisapproveDescription('');
        setDateEnd("");
      } else {
        setIssueSK(sk);
        retrieveIssueInfo();
      }
    }
  })

// eslint-disable-next-line 
  useEffect(() => {
    if (rsIssue !== undefined) {
        setFullName(rsIssue[0].fullName);
        setNeighborhoodSK(rsIssue[0].neighborhoodSK);
        setIsCityWide(rsIssue[0].isCityWide === '1'? true : false);
        setIsActive(rsIssue[0].isActive === '1' ?true : false);
        setIsPrivate(rsIssue[0].isPrivate === '1' ?true : false);
        setBody(rsIssue[0].body);
        setImageFileName(baseURL + "upload/" + rsIssue[0].imageFileName);
        setApproveDescription(rsIssue[0].approveDescription);
        setDisapproveDescription(rsIssue[0].disapproveDescription);
        setIsInitialized(true);
        setDateEnd(rsIssue[0].dateEnd);
      }
   // eslint-disable-next-line    
  },[rsIssue])

  // eslint-disable-next-line 
  useEffect(() => {
    setIsInitialized(false);
  }, [sk])

  //---------------------------------------------------
  const handleNext = async () => {

    //-------------------
    //--- basic info step
    if (activeStep === 0) {
      const data = IssueAddFormStep1.getData();
      let isError = false;
      if ((data.fullName === "") ) {
        setErrorMessage("The name of the topic is required.");
        isError = true;
      };
      if ((data.neighborhoodSK === '0') && (data.isCitywide === false) ) {
        setErrorMessage("The city-wide flag should be set for topics without a neighborhood.");
        isError = true;
      };
      if (data.isCitywide === true) {
        data.isPrivate = false;
      };
      if (isError === false) {
        setFullName(data.fullName);
        setNeighborhoodSK(data.neighborhoodSK);
        setIsCityWide(data.isCitywide);
        setIsActive(data.isActive);
        setIsPrivate(data.isPrivate);
        setBody(data.body);
        setApproveDescription(data.approveDescription);
        setDisapproveDescription(data.disapproveDescription);
        setDateEnd(data.dateEnd);
        //save to database
        const formData = new FormData();
        formData.append('fullName', data.fullName);
        formData.append('neighborhoodSK', data.neighborhoodSK);
        formData.append('isCityWide', data.isCitywide ? 1 : 0);  //need to pass bit to php
        formData.append('isActive', data.isActive ? 1 : 0);  //need to pass bit to php
        formData.append('isPrivate', data.isPrivate ? 1 : 0);  //need to pass bit to php
        formData.append('body', data.body);
        formData.append('issueSK', issueSK);
        formData.append('approveDescription', data.approveDescription);
        formData.append('disapproveDescription', data.disapproveDescription);
        formData.append('dateEnd', (data.dateEnd === '') ? null : data.dateEnd );
        let objData = await postData(baseURL + 'issueAddEdit.php', formData);
        if (objData.theError === "") {
          //retrieve effective issueSK (in case it was a new one)
          setIssueSK(objData.theData[0].issueSK);
          //successful, so move to next step
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
          setErrorMessage(objData.theError);
        }
      } //else -required fields are complete
    }
    //-------------------
    //--- image file step
    if (activeStep === 1) {
      // Retrieve data from the child component using any appropriate method
      const data = IssueAddFormStep2.getData(); 
      if (data.clearImage === true) {
        //clearing the image from the issue
        const formData = new FormData();
        formData.append('issueSK', issueSK);
        let objData = await postData(baseURL + 'uploadImageClear.php', formData);
        if (objData.theError === "") {
          //successful, so move to next step
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setImageFileName(null);
        } else {
          setErrorMessage(objData.theError);
        }
      } else {
        //save the file
        const theNewFile = data.theFile;
        if (theNewFile !== null) {
          const formData = new FormData();
          formData.append('fileToUpload', data.theFile);
          formData.append('issueSK', issueSK);
          let objData = await postData(baseURL + 'uploadImage.php', formData);
          if (objData.theError === "") {
            //successful, so move to next step
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //set image to new file
            const fileExtension = data.theFile.name.split('.').pop();
            setImageFileName(baseURL + "upload/image" + issueSK + "."+ fileExtension);
          } else {
            setErrorMessage(objData.theError);
          }
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }//else - not null file

      } //else-clearImage
    }  //step1

    //-------------------

    //--- addresses step
    if (activeStep === 2) {      
      // Retrieve data from the child component using any appropriate method

      let strAddress = "";
      let data = IssueAddFormStep3.getData();
      if (data.selectedOption !== undefined) {
        setRecipient(data.selectedOption);
        //convert recipient data to single string (with pipe delimited SKs)
        
  
        for (let i=0; i<data.selectedOption.length;i++){
          strAddress += data.selectedOption[i].value + "|"
        }
      }

      //save list up on the server
      const formData = new FormData();
      formData.append('strAddress', strAddress);
      formData.append('issueSK', issueSK);
      let objData = await postData(baseURL + 'issueAddressbookAddEdit.php', formData);
      if (objData.theError === "") {
        //successful, so move to edit list page
        history.push(`/issueEdit`);
      } else {
        setErrorMessage(objData.theError);
      }
    }

  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const isStepOptional = (step) => {
    return step === 1; //which is actually the 2nd step
  };

  //---------------------------------------------------
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //---------------------------------------------------
  return (
    <div>
      {(sk === '0') && <h3>Adding a new topic</h3>}
      {(sk !== '0') && <h3>Editing '{title}' topic</h3>}
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}
              {(isStepOptional(index)) && <><br /><Typography variant="caption">Optional</Typography></>}
            </StepLabel>
            <StepContent>
              {/* Place your content for each step here */}
              <div style={{ marginBottom: "20px" }}>{/* Content for step {index + 1} */}
                {(index === 0 && isInitialized) && <div><IssueAddFormStep1
                  fullName={fullName}
                  neighborhoodSK={neighborhoodSK}
                  isCitywide={isCitywide}
                  isActive={isActive}
                  isPrivate={isPrivate}
                  body={body}
                  approveDescription={approveDescription}
                  disapproveDescription={disapproveDescription}
                  dateEnd={dateEnd}
                /></div>}
                {(index === 0 && isInitialized === false) && <div>
                  <Loadingalert/>
                  </div>}

            {index === 1 && <div><IssueAddFormStep2 image={imageFilename} /></div>} 
           

                {index === 2 && <div><IssueAddFormStep3 aryRecipent={Recipient} /></div>}
              </div>
              <div>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Back
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {activeStep === 0 && "Basic info Saving Error"}
          {activeStep === 1 && "Image File Upload Error"}
          {activeStep === 2 && "Recipient Saving Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default IssueAddForm;