
//---------------------------------------------------------------
//  used to communicate with database, both retrieving and sending
//
//---------------------------------------------------------------
async function postData(url, data) {
  var theError = "";
  var theData = null;
  var theData2 = null;

 // url = 'https://ronkashdensoftware.com/sarasotapublic/' + url;
 
  try {
    await fetch(url, {
      method: 'POST',
      body: data
    })
      .then(response => response.json())
      .then(jsonData => {
        // Handle the response
        theData = jsonData.theDataRows;
        if (jsonData.sqlResult === false ) {
          throw (new Error(jsonData.sqlMessage + " (db)"))
      }
      if (jsonData.theDataRows2 !== undefined) {
        theData2 = jsonData.theDataRows2;
      }
      })
      .catch(error => {
        // Handle errors
        theError = error.message;
        console.error(error);
      });
  } catch (error) {
    theError = 'Error fetching JSON data:(' + url + '):' + error.message;
    console.error(theError);
  }
  return { theData, theError  ,theData2};
}

export default postData;