import React, { useState, useEffect, useContext, useMemo,useRef } from 'react';
import { useHistory } from 'react-router-dom';
import connectionImage from './images/people.png';
import Loadingalert from './LoadingAlert';
import { Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, RadioGroup,Radio,FormControlLabel, Tooltip } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import DeleteIcon from '@mui/icons-material/Clear';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles-custom.css'; //referenced after core styles for ag-grid

function IssueListEdit() {
  const { baseURL ,isAdministrator ,neighborhoodSK } = useContext(AuthContext);
  const history = useHistory();
  const [internalError, setInternalError] = useState('');
  const [aryIssue, setIssueArray] = useState(undefined);
  const [aryIssueFiltered, setIssueArrayFiltered] = useState(undefined);
  const [isArrayInitialized, setIsArrayInitialized] = useState(false);
  const [query, setQuery] = useState('');
  const [isActive,setIsActive] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  var bIsInitialized = false;

// topic grid variables
  const gridRef = useRef();


  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const columnDefs = [
    {headerName: '', field: 'commentNumber',colId:'deleteCol', width:60, cellRenderer: params => {
        if (params.value > 0) {
          return <></>;
        } else {
          return <Tooltip title="delete the topic"><DeleteIcon sx={{ color: 'var(--colorDisapprove)', padding:'5px' }} /></Tooltip>;     
        }
      }
    },
    {headerName: '', field: 'commentNumber',colId:'countCol', width:80, cellRenderer: params => {
      if (params.value > 0) {
        return <Badge badgeContent={params.data.commentNumber} >
        <img alt="" width="20" height="20" style={{ marginRight: '5px' }} src={connectionImage} /></Badge>;
      } else {
        return <></>;     
      }
    }
  },
    { headerName: 'Topic', field: 'fullName', flex: 4 },
    { headerName: 'Neighborhood', field: 'neighborhoodName', flex: 1 }
  ];

  const defaultColDef = {
    sortable: true, 
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressMovable : true
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const doCellClicked = (params) => {
    //handle click in data grid
    //check to see if it's in the delete column, if so handle delete
    //otherwise go to editing the topic
    if (params.colDef.colId === "deleteCol") {
      //only delete if there are no submissions
      if (params.data.commentNumber === null) {
        handleItemDelete(params.data.sk, params.data.fullName);
      }
    } else {
      handleItemClick(params.data.sk, params.data.fullName);
    }
  };

  //-------------------------------------------------------------

  //vars to handle delete
  const [deleteSK, setDeleteSK] = useState(undefined);
  const [deleteText, setDeleteText] = useState(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDataReset, setIsDataReset] = useState(false);

  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //-------------------------------------------------------------
  // handle list selection

  const handleItemClick = (item, title) => {
    history.push(`/issueAdd/${item}/${title}`);
  };

  //-------------------------------------------------------------
  // functions to handle delete functionality

  const handleItemDelete = (itemSK, itemTitle) => {
    setDeleteSK(itemSK);
    setDeleteText(itemTitle)
    setOpenDeleteDialog(true);
  };

 const handleDialogCancel = () => {
  setDeleteSK(undefined);
  setDeleteText(undefined)
  setOpenDeleteDialog(false);
}

  const handleDialogOK = async () => {
    // close the dialog
    setOpenDeleteDialog(false);

    if (deleteSK === undefined) {
      setErrorMessage("the sk to delete is undefined");
    } else {
      //save to database
      const formData = new FormData();
      formData.append('issueSK', deleteSK);
      let objData = await postData(baseURL + 'issueDelete.php', formData);
      if (objData.theError === "") {
        // refresh topic list
        setIsDataReset(true);
      } else {
        setErrorMessage(objData.theError);
      }

    }
  }
  
useEffect(() => {
  if (isDataReset){
    // eslint-disable-next-line
    bIsInitialized = false;
    setIssueArray(undefined);
    setIsArrayInitialized(false);
    setRetryCount(retryCount - 1);
  }// eslint-disable-next-line
} ,[isDataReset])
  
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleErrorDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //-------------------------------------------------------------
  // get the topic list from the server

  const getIssueList = async () => {
    if (isArrayInitialized === false) {
      const formData = new FormData();
      formData.append('issueSK', null);
      formData.append('isActive', isActive);
      let $neighborhoodSKEffective = neighborhoodSK;
      //for administrators, show all issues
      if (isAdministrator === '1') {
        $neighborhoodSKEffective = null;
      };
      formData.append('neighborhoodSK', $neighborhoodSKEffective);
      var objEntity = await postData(baseURL + 'issueSelect.php', formData);
      setInternalError(objEntity.theError);
      if (bIsInitialized === false){
      if ((aryIssue === undefined) && (objEntity.theData !== null)) {
        setIssueArray(objEntity.theData);
        setIssueArrayFiltered(objEntity.theData);
        setIsArrayInitialized(true);
        setIsDataReset(false);
        bIsInitialized = true;
      } 
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 

    }
  }
  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [retryCount]);

  useEffect(() => {
    setIssueArray(undefined);
    setIsArrayInitialized(false);
    setRetryCount(0);
  }, [isActive]);

  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [isArrayInitialized]);

  //-------------------------------------------------------------

  useEffect(() => {
    // filter the list when query changes
    if (aryIssue === undefined) {
      return undefined
    } else {
      setIssueArrayFiltered(aryIssue.filter((row) => row.fullName.toLowerCase().includes(query.toLocaleLowerCase())));
    }
  }, [query, aryIssue])
  //-------------------------------------------------------------

  return (
    <>
      <div className="searchable-list">
        <h3>Select a topic to edit</h3>
        <br />
        <div align="left" style={{ paddingBottom: '20px',display: "flex", flexDirection: "row"  }}>
          <input
            type="text"
            placeholder="Search..."
            id="searchbox"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        <RadioGroup
              name="isActiveRadio"
              value={isActive}
              onChange={(event) => setIsActive(event.target.value)}
              row
              sx={{ paddingTop: '5px', paddingBottom: '15px' }}
              required
            >
            <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="1" value={true} control={<Radio sx={{ padding: '1px' }} />} label="Active" />
            <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="0" value={false} control={<Radio sx={{ padding: '1px' }} />} label="Inactive" />
          </RadioGroup>
        </div>
        {(aryIssueFiltered === undefined) && <Loadingalert />}
        <div id="gridContainer" style={{ height: '70vh', width: '100%' ,textAlign:'left' }}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <AgGridReact
                    ref={gridRef}
                    rowData={aryIssueFiltered}
                    popupParent={popupParent}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}                    
                   onCellClicked={doCellClicked}
                  />
                </div>
              </div>
        {(internalError !== "" && retryCount > 2) && <div>Error:{internalError}</div>}
      </div>
      <Dialog
        open={openDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Topic Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the topic '{deleteText}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancel</Button>
          <Button onClick={handleDialogOK}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Issue Deletion Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default IssueListEdit;