import { React } from 'react';
import IssueListComment from './issueListComment';
import { useParams } from 'react-router-dom';

const HomeNeighborhood = () => {

  const {sk } = useParams();  //get parameter from router

    return (

        <div className="homeNeighborhood" >
            <h3>Having your voice heard...</h3>
            <br />
            <IssueListComment neighborhoodSK= {sk} />
        </div>
    );
}
 
export default HomeNeighborhood;