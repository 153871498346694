import React, { useState, useRef,useEffect, useContext ,useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Loadingalert from './LoadingAlert';
import postData from './postData';
import { AuthContext } from './AuthContext';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles-custom.css'; //referenced after core styles for ag-grid


function IssueListComment(params) {
  const {baseURL} = useContext(AuthContext);
  const history = useHistory();
  const [internalError, setInternalError] = useState('');
  const [aryIssue, setIssueArray] = useState(undefined);
  const [aryListFiltered, setIssueArrayFiltered] = useState(undefined);
  const [query] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const [headingText, setHeadingText] = useState('All');

  // comment grid variables
  const gridRef = useRef();


  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const columnDefs = [
    { headerName: 'Topic', field: 'fullName',flex: 4},
    { headerName: 'Neighborhood', field: 'neighborhoodName',flex:1 },
    { headerName: 'End Date', field: 'dateEndFormat',flex:1 }
  ];

  const defaultColDef = {
    sortable: true, 
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressMovable : true
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  
  //-------------------------------------------------------------
  // handle list selection

  const handleItemClick = (item) => {
    history.push(`/issueComment/${item}`);
  };


  //-------------------------------------------------------------
  // get the topic list from the server

  const getIssueList = async () => {
    if (aryIssue === undefined) {
      const formData = new FormData();
      formData.append('neighborhoodSK', params.neighborhoodSK);
      var objEntity = await postData(baseURL + 'issueListHomeSelect.php', formData);
      setInternalError(objEntity.theError);
      if (objEntity.theData2 !== null) {
        setIssueArray(objEntity.theData2);
        setIssueArrayFiltered(objEntity.theData2);
        if (objEntity.theData.length > 0) {
          setHeadingText(objEntity.theData[0].fullName);
        }        
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 
    }
  }
  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [retryCount]);

  //-------------------------------------------------------------

  useEffect(() => {
    // filter the list when query changes
    if (aryIssue === undefined) {
      return undefined
    } else {
      setIssueArrayFiltered(aryIssue.filter((row) => row.fullName.toLowerCase().includes(query.toLocaleLowerCase())));
    }
  }, [query, aryIssue])
  //-------------------------------------------------------------
  const doCellClicked = (eventParams) => {
    // Handle cell click event here
    handleItemClick(eventParams.data.sk);
  };

  return (

    <div className="searchable-list">
      <h4 style = {{textAlign:'left'}}>{headingText} Topics</h4>
      {(aryListFiltered === undefined) && <Loadingalert />}
      <div id="gridContainer" style={{ height: '400px', width: '100%' ,textAlign:'left' }}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <AgGridReact
                    ref={gridRef}
                    rowData={aryListFiltered}
                    popupParent={popupParent}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}                    
                   onCellClicked={doCellClicked}
                  />
                </div>
              <p style={{marginTop:'20px',fontSize:'0.7em'}}>The topics above were created by individual neighborhoods.<br/>
              The information contained in these topics presents the view (and possibilly the opinion) of the neighborhood creating the topic and does not necessary represent the views or opinions of the Coalition of City Neighborhood Associations (<a href="https://sarasotaccna.org" target="_">CCNA)</a></p>
              </div>
      {(internalError !== "" && retryCount > 2) && <div>Error:{internalError}</div>}
    </div>
  );
}

export default IssueListComment;