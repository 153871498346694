import React, { useState, useEffect, useContext } from 'react';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import CheckBoxOutlineBlankRoundedIcon from '@mui/icons-material/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';


function IssueListSupport() {
  const { baseURL, neighborhoodSK ,neighborhoodName } = useContext(AuthContext);
  const [aryIssue, setIssueArray] = useState(undefined);
  const [aryIssueFiltered, setIssueArrayFiltered] = useState(undefined);
  const [isArrayInitialized, setIsArrayInitialized] = useState(false);
  const [query, setQuery] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  const [isDataReset, setIsDataReset] = useState(false);

  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

   //-------------------------------------------------------------
  // functions to handle user clicks

  const handleItemSupport = async (issueSK) => {
    //save to database
    const formData = new FormData();
    formData.append('issueSK', issueSK);
    formData.append('neighborhoodSK', neighborhoodSK); //obtained from user
    let objData = await postData(baseURL + 'issueSupportAdd.php', formData);
    if (objData.theError === "") {
      // refresh topic list
      setIsDataReset(true);
    } else {
      setErrorMessage(objData.theError);
    }
  };

  const handleItemDelete = async (issueSupportSK) => {
    //save to database
    const formData = new FormData();
    formData.append('issueSupportSK', issueSupportSK);
    let objData = await postData(baseURL + 'issueSupportDelete.php', formData);
    if (objData.theError === "") {
      // refresh topic list
      setIsDataReset(true);
    } else {
      setErrorMessage(objData.theError);
    }
  };
  //-------------------------------------------------------------

  useEffect(() => {
    if (isDataReset) {
      setIssueArray(undefined);
      setIsArrayInitialized(false);
      setRetryCount(retryCount - 1);
    }
    // eslint-disable-next-line
  }, [isDataReset])

  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleErrorDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //-------------------------------------------------------------
  // get the topic list from the server

  const getIssueList = async () => {
    if (isArrayInitialized === false) {
      const formData = new FormData();
      formData.append('issueSK', null);
      let $neighborhoodSKEffective = neighborhoodSK;

      formData.append('neighborhoodSK', $neighborhoodSKEffective);
      var objEntity = await postData(baseURL + 'issueSupportSelect.php', formData);
      setErrorMessage(objEntity.theError);
      if (objEntity.theData !== null) {
        setIssueArray(objEntity.theData);
        setIssueArrayFiltered(objEntity.theData);
        setIsArrayInitialized(true);
        setIsDataReset(false);
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 
    }
  }
  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [retryCount]);


  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [isArrayInitialized]);

  //-------------------------------------------------------------

  useEffect(() => {
    // filter the list when query changes
    if (aryIssue === undefined) {
      return undefined
    } else {
      setIssueArrayFiltered(aryIssue.filter((row) => row.fullName.toLowerCase().includes(query.toLocaleLowerCase())));
    }
  }, [query, aryIssue])
  //-------------------------------------------------------------

  return (
    <>
      <div className="searchable-list">
        <h3>Select topics for {neighborhoodName} to support</h3>
        <br />
        <div align="left" style={{ paddingBottom: '20px', display: "flex", flexDirection: "row" }}>
          <input
            type="text"
            placeholder="Search..."
            id="searchbox"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {(aryIssueFiltered === undefined) && <Loadingalert />}
        <table className="supportList">
          <colgroup>
            <col span="1" style={{ width: '30px' }} />
          </colgroup>
          <thead><tr><th></th><th>Title</th><th>Neighborhood</th></tr></thead>
          <tbody>
            {(aryIssueFiltered !== undefined) && aryIssueFiltered.map((row) => (
            <tr key={row.issueSK}>
                {(row.issueSupportSK === null) && <>
                  <td key={row.issueSK + "_check"} className='supportListCheckbox' onClick={() => handleItemSupport(row.issueSK)}><CheckBoxOutlineBlankRoundedIcon fontSize='small' sx={{ color: '#2b9ed4' }} /></td></>}
                {(row.issueSupportSK !== null) && <>
                  <td key={row.issueSK + "_check"} className='supportListCheckbox' onClick={() => handleItemDelete(row.issueSupportSK)}><CheckBoxRoundedIcon fontSize='small' sx={{ color: '#2b9ed4' }} /></td></>}
                <td key={row.issueSK + "_name"}  >{row.fullName}</td>
                <td key={row.issueSK + "_neighbor"} >{row.neighborhoodName}</td>
              </tr>
            ))}

          </tbody></table>
      </div>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Issue Support Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default IssueListSupport;