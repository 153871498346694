import { React, useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { MySelect } from './formFunction';
import postData from './postData';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from './AuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const EmailGenerateForm = () => {
  const { baseURL, isAdministrator } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEmailProcessStarted, setIsEmailProcessStarted] = useState(false);
  const history = useHistory();

  //vars supporting combo box
  const [rsCombo, setrsCombo] = useState(undefined);
  const [selectedOption, setSelectedOption] = useState('0');
  const [numOfDays ,setNumOfDays] = useState('7');

  //vars supporting warning dialog
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  //vars supporting result dialog
  const [openResultDialog, setOpenResultDialog] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  // ----------------------------------------------------
  // make sure the user is an administrator, if not send them back to the home back
  useEffect(() => {
    if (isAdministrator === null || isAdministrator === false) {

      history.push("/");
    }
  }, [isAdministrator, history]);

  // ----------------------------------------------------
  // get address book list from server

  useEffect(() => {
    const getSelectOptions = async () => {
      const formData = new FormData();
      var objSelect = await postData(baseURL + 'addressbookSelect.php', formData);
      setErrorMessage(objSelect.theError);
      if (objSelect.theData != null) {
        setrsCombo(objSelect.theData);
      }
    }
    if (rsCombo === undefined) {
      getSelectOptions();
    }
  })
  // ----------------------------------------------------
  // ----- handle user warning

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  // ----------------------------------------------------
  // ----- handle results

  useEffect(() => {
    if (resultMessage !== '') {
      setOpenResultDialog(true);
    }
  }, [resultMessage]
  )
  const handleResultDialogOK = () => {
    history.push('/');
  };
  // ----------------------------------------------------
  const trySendingEmail = async () => {
    const formData = new FormData();
    formData.append('addressbookSK', selectedOption);
    let effectiveDays = parseInt(numOfDays)+1;
    formData.append('days', effectiveDays);

    var objData = await postData(baseURL + 'issueEmail.php', formData);

    if (objData.theError !== "") {
      setIsSubmitting(false);
      setErrorMessage(objData.theError);
    } else {
      if (objData.theData != null) {
        setResultMessage("Email successfully sent:" + objData.theData.numSuccessful + "<br/><br/>"
          + "Emails failed:" + objData.theData.numFailed);
      }
    } //else-error
  } //trySendingEmail

  useEffect(() => {
    if (isSubmitting === true && isEmailProcessStarted === false) {
      trySendingEmail();
      setIsEmailProcessStarted(true);
    }
    //eslint-disable-next-line
  }, [isSubmitting, trySendingEmail]
  )

  // ----------------------------------------------------
  return (
    <>
      <h2>Regenerate Email</h2>
      {rsCombo === undefined && <Loadingalert />}
      {rsCombo !== undefined &&
        <Formik
          initialValues={{
            userFullName: '',
            userPassword: ''
          }}
          onSubmit={async (values) => {
            setIsSubmitting(true);
          }}
        >
          <Form>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ float: 'left' }}>
                <div style={{ marginBottom: '20px', marginTop: '30px' }}>
                  <MySelect label="Recipient" name="addressbookSK"
                    id="addressbookCombo"
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)} >
                    <option key="0" value="0">All Recipients</option>
                    {(rsCombo) && rsCombo.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>))}
                  </MySelect>
                  <label htmlFor="numDays" style={{ marginTop: "20px" }}>Number of days user comments</label>
                  <Field
                    name="numDays"
                    type="text"
                    value={numOfDays}
                    onChange={(e) => setNumOfDays(e.target.value)}
                  />
                </div>
                <div className='formButtonContainer'>
                  <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting} variant='contained'>Generate Email</LoadingButton>
                </div>
              </div></div>
          </Form>
        </Formik>
      }
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Addressbook Retrieval Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openResultDialog}
        aria-labelledby="result-dialog-title"
      >
        <DialogTitle id="result-dialog-title">
          Email Results
        </DialogTitle>
        <DialogContent>
          <div dangerouslySetInnerHTML={{ __html: resultMessage }}></div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleResultDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EmailGenerateForm;