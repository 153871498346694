
import { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText ,Paper } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';


const IssueAddFormStep3 = (props) => {

    const { baseURL } = useContext(AuthContext);
    // ----------------------------------------------------

    IssueAddFormStep3.getData = () => {
        return {
            selectedOption
            
          //  (selectedOption)
        };
    };

    // ----------------------------------------------------
    //vars supporting combo box
    const animatedComponents = makeAnimated();

    //vars supporting combo box
    const [rsCombo, setrsCombo] = useState(undefined);
    const [selectedOption, setSelectedOption] = useState(props.aryRecipent);

    //vars supporting warning dialog
    const [openWarningDialog, setOpenWarningDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    // ----------------------------------------------------
    // get address book list from server
    useEffect(() => {
        const getSelectOptions = async () => {
            const formData = new FormData();
            var objSelect = await postData(baseURL + 'addressbookSelect.php', formData);
            setErrorMessage(objSelect.theError);
            if (objSelect.theData != null) {
                setrsCombo(objSelect.theData);
            }
        }
        if (rsCombo === undefined) {
            getSelectOptions();
        }
    })
    // ----------------------------------------------------
    // ----- handle user warning
    useEffect(() => {
        if (errorMessage !== '') {
            setOpenWarningDialog(true);
        }
    }, [errorMessage]
    )
    const handleDialogOK = () => {
        setOpenWarningDialog(false);
        setErrorMessage('');
    };
    // ----------------------------------------------------
    return (<>
    <Paper elevation={10}><div style={{padding:10}}>
        <Select
            components={animatedComponents}
            defaultValue={selectedOption}
            onChange={setSelectedOption}
            isMulti
            options={rsCombo}
        /></div>
        </Paper>
        <Dialog
            open={openWarningDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Addressbook Retrieval Error
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {errorMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogOK}>Ok</Button>
            </DialogActions>
        </Dialog>
    </>);
}

export default IssueAddFormStep3;