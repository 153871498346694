import { Paper } from "@mui/material";
import screenListSelection from './images/aboutScreenListSelection.png';
import screenFeedback from './images/aboutScreenFeedbackScreen.png';
import IconCopyright from "@mui/icons-material/Copyright"
import { Link } from 'react-router-dom';

const PageAbout = () => {
  return (<>
    <h2>About Sarasota Public</h2>
    <div style={{justifyContent:'center',display:'flex'}}>
<Paper elevation={12} sx={{textAlign:'left',padding:'20px',width:'80vw'}}>
  <h4>Why....</h4>

  <p style={{marginBottom:"5px"}}>Sarasota Public makes public's input more impactful than ordinary emails and phone calls.</p>
  <ul className="aboutPageList">
  <li className="aboutPageListItem">by giving neighborhoods the ability to raise the topics,</li> 
  <li className="aboutPageListItem">so residents can weighing in on their thoughts,</li>
  <li className="aboutPageListItem">and giving neighborhoods a chance to support that topics raised by other neighborhoods,</li>
  <li className="aboutPageListItem">then combining residents sentiments into a consolidated weekly email that gets sent to city hall and community leaders 
  (which includes running tallys of neighborhood input). One weekly email allows the recipient to focus rather than being distracted by separate emails thoughout the day,</li>
  <li className="aboutPageListItem">providing neighborhood leaders with a single document for all the feedback, eliminating the need for a separate petition. </li>
  </ul>
  It's an email  & survey & petition all wrapped up in one.

  <h4>How....</h4>
  There's just 2 simple steps...
  <ol className="aboutPageList" style={{marginTop:'10px'}}>
  <li className="aboutPageListItem">Select a topic that you have an opinion on,<br/>
  <img alt="selecting a topic" src={screenListSelection} className="aboutPageImage"/> </li> 
  <li className="aboutPageListItem">Indicate your sentiment and write your thoughts,<br/>
  <img alt="entering your opinion" src={screenFeedback} className="aboutPageImage"/></li>
  </ol>
  <h5>Support....</h5>
  Email <a href="mailto:support@sarasotapublic.com">support@sarasotapublic.com</a> with any questions or issues.<br/>
  <p style={{marginTop:'10px'}}>Click <Link to="/privacy">here</Link> for the privacy policy of Sarasota Public.<br/>
  <IconCopyright sx={{padding:'10px 5px 0 0'}}/>2023 by Ron Kashden. All rights reserved.
  <br/><span style={{fontSize:'0.7rem'}}>(September 19,2023 build)</span></p>

   </Paper>
   </div>
  </>  );
}
 
export default PageAbout;