import { React, useState, useContext, useEffect } from 'react';
import { Formik, Form } from 'formik';
//import getData from './getData';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MyTextInput } from './formFunction';
import { useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import postData from './postData';

// ----------------------------------------------------

const UserLoginForm = () => {

  const [retryCount, setRetryCount] = useState(0);
  const [userFullName, setUserFullName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { login, baseURL } = useContext(AuthContext);

  let history = useHistory();
  // ----------------------------------------------------

  const clickForgot = async () => {
    //make sure a user has been entered
    if (userFullName === "") {
      setErrorMessage("The user ID must be entered in order to reset your password.")
    } else {
      // look up userID and if found, reset the password and email user with new password
      setIsSubmitting(true);   
      const formData = new FormData();
      formData.append('userFullName', userFullName);
      var objData = await postData(baseURL + 'appUserForgotPassword.php', formData);
      setIsSubmitting(false);
      if (objData.theError !== "") {
        setErrorMessage(objData.theError);        
      } else {
        if (objData.theData != null) {
          setErrorMessage("The password has been reset and a new password has been emailed to " + objData.theData[0].email);
        } else {
          setErrorMessage("internal error:no email found for user");
        } //theData = null
      } // theError
    } //userFullName
  }

  // ----------------------------------------------------

  const tryLogin = async () => {

    const formData = new FormData();
    formData.append('userFullName', userFullName);
    formData.append('userPassword', userPassword);
    var objData = await postData(baseURL + 'AppUserLogin.php', formData);
 
    if (objData.theError !== "") {
      // Retry logic
      if (retryCount < 3) {
        // Retry after 3 seconds
        setTimeout(() => setRetryCount(retryCount + 1), 3000);
      } else {
        setErrorMessage(objData.theError);
      } //retry
    } else {
      if (objData.theData != null) {

        setIsSubmitting(false);
        login(objData.theData[0].sk, objData.theData[0].neighborhoodSK, objData.theData[0].isAdministrator
          , objData.theData[0].neighborhoodName, objData.theData[0].userFullName);
        history.push('/');
      } else {
        // Retry logic
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } else {
          setIsSubmitting(false);
        } //retry
      } //else     
    } //else-error
  } //tryLogin

  useEffect(() => {
    if (isSubmitting === true) {
      tryLogin();
    }
    // eslint-disable-next-line
  }, [retryCount, isSubmitting]
  )

  // ----------------------------------------------------
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setIsSubmitting(false);
    setErrorMessage('');
  };
  // ----------------------------------------------------

  return (<>
    <h2>User Login</h2>
    <Formik
      initialValues={{
        userFullName: '',
        userPassword: ''
      }}
      onSubmit={async (values) => {
        setIsSubmitting(true);
      }}
    >
      <Form>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ float: 'left' }}>
            <MyTextInput
              label="User ID"
              name="userFullName"
              type="text"
              value={userFullName}
              onChange={(e) => setUserFullName(e.target.value)}
              required
            />

            <MyTextInput
              label="Password"
              name="userPassword"
              type="password"
              value={userPassword}
              onChange={(e) => setUserPassword(e.target.value)}
              required
            />

            <div className='formButtonContainer'>
              <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting} variant='contained'>Login</LoadingButton>
              <Button variant='outlined' onClick={() => clickForgot()} disabled={isSubmitting}>Forgot Password</Button>
            </div>
          </div></div>
      </Form>
    </Formik>
    <Dialog
      open={openWarningDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Login error"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogOK}>Ok</Button>
      </DialogActions>
    </Dialog>
  </>);
}

export default UserLoginForm;