import { useContext, useMemo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from "react-router-dom";
import { AuthContext } from './AuthContext';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import Divider from '@mui/material/Divider';
import bullhornImage from './images/bullhorn.png';
import { Tooltip } from '@mui/material';


const NavAppBar = () => {
  //-------------------------------------------------------------
  // get user id
  const { userID, logout, isAdministrator, neighborhoodSK ,userName,neighborhoodName } = useContext(AuthContext);
  const color_hover = '#def0f8';

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const userPermissionText = useMemo(() => {
    if (isAdministrator === '0' ) {
      return "neighborhood permissions for " + neighborhoodName;
    } else if (isAdministrator === '1' && neighborhoodSK === null) {
      return "administrator permissions";
    } else {
      return "administrator and neighborhood permissions for " + neighborhoodName;;
    }
  },[isAdministrator,neighborhoodName,neighborhoodSK]);
  //-------------------------------------------------------------
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuAbout = () => {
    setAnchorEl(null);
    history.push("/about");
  };
  const handleMenuInstruction = () => {
    setAnchorEl(null);
    history.push("/instructions");
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleMenuLogout = () => {
    setAnchorEl(null);
    logout();
    history.push("/");
  };
  const handleMenuLogin = () => {
    setAnchorEl(null);
    logout();
    history.push("/userLogin");
  };

  const handleMenuCCNA = () => {
    setAnchorEl(null);
    window.open("https://sarasotaccna.org");
  };

  const handleMenuChangePassword = () => {
    setAnchorEl(null);
    history.push("/userChangePassword");
  };
  const handleMenuIssueAdd = () => {
    setAnchorEl(null);
    history.push("/issueAdd/0/''");
  };
  const handleMenuIssueEdit = () => {
    setAnchorEl(null);
    history.push("/issueEdit");
  };
  const handleMenuIssueSubmission = () => {
    setAnchorEl(null);
    history.push("/issueSubmission");
  };
  const handleMenuIssueSupport = () => {
    setAnchorEl(null);
    history.push("/issueSupport");
  };

  const handleMenuGenerateEmail = () => {
    setAnchorEl(null);
    history.push("/emailGenerate");
  };

  const handleMenuDistributionLogs = () => {
    setAnchorEl(null);
    window.open("https://app.sendgrid.com");
  };

  const handleMenuScheduledTaskLogs = ()  =>{
    setAnchorEl(null);
    history.push("/cronLog");
  };
  const handleMenuUserAdd = () => {
    setAnchorEl(null);
    history.push("/userAdd/0");
  };
  const handleMenuUserEdit = () => {
    setAnchorEl(null);
    history.push("/userEdit");
  };
  const handleMenuRecipientAdd = () => {
    setAnchorEl(null);
    history.push(`/recipientAdd/0/null/null`);
  };
  const handleMenuRecipientEdit = () => {
    setAnchorEl(null);
    history.push("/recipientEdit");
  };
  //-------------------------------------------------------------
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" style={{ backgroundColor: '#2b9ed4', height: '60px' }}>
        <Toolbar>
          <Typography variant="h4"
            onClick={() => { history.push("/") }}
            component="div" sx={{'&:hover':{cursor:'pointer'}, flexGrow: 1, textAlign: 'left', fontWeight: 'bold', display: 'flex' }}>
            Sarasota Public <img alt="" style={{ width: '50px', horizontal: 'left', position: 'relative', top: '-13px', left: '7px' }} src={bullhornImage} />
          </Typography>
          <span >
            {(userName !== null) && 
            <span style={{fontSize:'7pt',margin:'0px'}}><Tooltip title={userPermissionText}><span>
            ({userName})</span></Tooltip>
            </span>}
            <Button color="inherit" sx={{
              ':hover': { backgroundColor: color_hover }
            }}
              onClick={() => { handleMenuCCNA(); }}>CCNA
            </Button>
            <Button color="inherit" sx={{
              ':hover': { backgroundColor: color_hover }
            }}
              onClick={() => { history.push("/") }}>Home
            </Button>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              aria-haspopup="true"
              onClick={handleMenu}
              aria-controls="menu-appbar"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 38,
                horizontal: 'center',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleMenuAbout} sx={{
                ':hover': { backgroundColor: color_hover }
              }}>About Sarasota Public</MenuItem>

              {(userID !== null) && <MenuList>
                <MenuItem onClick={handleMenuChangePassword} >Change Password</MenuItem>
                <MenuItem onClick={handleMenuLogout} >Log out</MenuItem>

                <Divider />
                <MenuItem onClick={handleMenuInstruction} sx={{
                  ':hover': { backgroundColor: color_hover }
                }}>Instructions</MenuItem>
              </MenuList>
              }


              {(userID === null) &&
                <MenuItem onClick={handleMenuLogin} sx={{
                  ':hover': { backgroundColor: color_hover }
                }}>Log in</MenuItem>}
              {(userID !== null) &&
                <MenuList>
                  <Divider />
                  <MenuItem onClick={handleMenuIssueAdd} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Add Topic</MenuItem>
                  <MenuItem onClick={handleMenuIssueEdit} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Edit Topics</MenuItem>
                  {(neighborhoodSK != null && neighborhoodSK !== 0) &&
                    <MenuItem onClick={handleMenuIssueSupport} sx={{
                      ':hover': { backgroundColor: color_hover }
                    }}>Give Support</MenuItem>
                  }
                  <MenuItem onClick={handleMenuIssueSubmission} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Submissions</MenuItem>
                </MenuList>
              }

              {(isAdministrator === '1') &&
                <MenuList>
                  <Divider />
                  <MenuItem onClick={handleMenuGenerateEmail} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Regenerate Email</MenuItem>
                  <MenuItem onClick={handleMenuDistributionLogs} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Email Delivery Logs</MenuItem>
                  <MenuItem onClick={handleMenuScheduledTaskLogs} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Scheduled Tasks Logs</MenuItem>
                </MenuList>
              }
              {(isAdministrator === '1') &&
                <MenuList>
                  <Divider />
                  <MenuItem onClick={handleMenuUserAdd} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Add User</MenuItem>
                  <MenuItem onClick={handleMenuUserEdit} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Edit Users</MenuItem>
                </MenuList>
              }
              {(isAdministrator === '1') &&
                <MenuList>
                  <Divider />
                  <MenuItem onClick={handleMenuRecipientAdd} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Add Recipient</MenuItem>
                  <MenuItem onClick={handleMenuRecipientEdit} sx={{
                    ':hover': { backgroundColor: color_hover }
                  }}>Edit Recipients</MenuItem>
                </MenuList>
              }

            </Menu>
          </span>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default NavAppBar;