import { Paper } from "@mui/material";
import { AuthContext } from './AuthContext';
import { useContext, useMemo } from "react";
import imageAddTopic1 from './images/instructions_addTopic1.png';
import imageAddTopic2 from './images/instructions_addTopic2.png';
import imageAddTopic3 from './images/instructions_addTopic3.png';
import imageGiveSupport from './images/instructions_giveSupport.png';

const PageInstructions = () => {
  //---------------------------------------------------------------

  const { isAdministrator, neighborhoodSK, neighborhoodName, baseURL } = useContext(AuthContext);


  //---------------------------------------------------------------
  const textHeading = useMemo(() => {
    if (isAdministrator !== '0' && neighborhoodSK !== null) {
      return " administrator and neighborhood leader permissions";
    }
    if (isAdministrator !== '0' && neighborhoodSK === null) {
      return " administrator permissions";
    }
    return " neighborhood leader permissions";
  }, [isAdministrator, neighborhoodSK]
  )
  //---------------------------------------------------------------
  // helper function to navigate within the page

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  //---------------------------------------------------------------
  return (
    <>
      <h2>Instructions</h2>
      <p style={{ fontSize: "0.8em", textDecoration: "italics" }}>You are seeing this page because you have {textHeading}.</p>
      <div style={{ justifyContent: 'center', display: 'flex' }}>
        <Paper elevation={12} sx={{ textAlign: 'left', padding: '20px', width: '80vw' }}>
          <h4>Table of Contents</h4>
          <ul>
            <li><a href="#typesOfUsers" onClick={(e) => scrollToSection(e, 'id_typesOfUsers')}>Types of users</a></li>
            <li><a href="#neighborhoodView" onClick={(e) => scrollToSection(e, 'id_neighborhoodView')}>Neighborhood topic view</a></li>
            <li><a href="#addingtopic" onClick={(e) => scrollToSection(e, 'id_addingtopic')}>Adding topics</a></li>
            <li><a href="#editingtopic" onClick={(e) => scrollToSection(e, 'id_editingtopic')}>Editing topics</a></li>
            <li><a href="#reviewsubmissions" onClick={(e) => scrollToSection(e, 'id_reviewsubmissions')}>Review submissions</a></li>
            <li><a href="#givesupport" onClick={(e) => scrollToSection(e, 'id_givesupport')}>Give support</a></li>        
          </ul>
          {(isAdministrator !== '0') && <>
            <i>The following features are only shown to administrators</i>
            <ul>
              <li><a href="#regenerateemail" onClick={(e) => scrollToSection(e, 'id_regenerateemail')}>Regenerate email</a></li>
              <li><a href="#emaildeliverylog" onClick={(e) => scrollToSection(e, 'id_emaildeliverylog')}>Email delivery logs</a></li>
              <li><a href="#cronlog" onClick={(e) => scrollToSection(e, 'id_cronlog')}>Scheduled task log</a></li>
              <li><a href="#adduser" onClick={(e) => scrollToSection(e, 'id_adduser')}>Add user</a></li>
              <li><a href="#edituser" onClick={(e) => scrollToSection(e, 'id_edituser')}>Edit users</a></li>
              <li><a href="#addrecipient" onClick={(e) => scrollToSection(e, 'id_addrecipient')}>Add recipient</a></li>
              <li><a href="#editrecipient" onClick={(e) => scrollToSection(e, 'id_editrecipient')}>Edit Recipients</a></li>
            </ul>
          </>}
          <h4 id="id_typesOfUsers">Types of Users</h4>
          <div>
            There are 3 types of users:
            <ul>
              <li>General public: who can view and comment on active topics,</li>
              <li>Neighborhood users: who can do the following on behalf of their assigned neighborhood:
                <ul>
                  <li>Add topics (for their neighborhood)</li>
                  <li>Edit topics (for their neighborhood)</li>
                  <li>Review submissions (for their neighborhood)</li>
                  <li>Support topics (for neighborhoods other than theirs)</li>
                </ul>
              </li>
              <li>Administrators: have access to all the above functions for all neighborhood, with the exception of giving support.
                Administrators also have the ability to flag a topic as city-wide.
              </li>
            </ul>
          </div>

          <h4 id="id_neighborhoodView">Neighborhood Topic View</h4>
          <p>The home page shows all the active topics for all neighborhoods that are not designed "private"
            (more about topics settings later).
            {(neighborhoodSK !== null) && <>  If you want to view the topics for <b>just {neighborhoodName}</b> then use the following url:<span style={{background:'lightgrey'}}> <b>{baseURL}#/neighborhoodview/{neighborhoodSK}</b></span>.</>}
            {(neighborhoodSK === null) && <i>  Note:Users that are assigned with neighborhood leader permissions will see instructions regarding the URL to use for their neighborhood in this section.</i>}
          </p>

          <h4 id="id_addingtopic">Adding Topics</h4>
          <div>
            You can create new topics for
            {(neighborhoodSK !== null) && <>  {neighborhoodName}.  </>}
            {(neighborhoodSK === null) && <>  any neighborhood <i>(since you have administrator rights)</i>.</>}
            This is broken down into three steps.
            <ol>
              <li><b>Basic information:</b>  On this screen you enter the content of the topic, such as title, the body of the topic,
                and a brief description of what it means to “approve” or “oppose” the topic.<br />
                <img src={imageAddTopic1} alt="adding topic-basic info" className="instructionPageImage" />
                <p><i>Is Active:</i> Indicates if the topic can be seen by the public.  Inactive topics are still saved in the system
                  and their submissions can always be viewed.  If you’re drafting a topic over a few days and don’t want the public to see the issue just yet, then set the topic it to inactive.
                </p>
                <p><i>Is Private:</i> Topics that are set as private cannot be seen by other neighborhoods.  They can only be seen on the neighborhood topic view.
                  It's handy to set a topic as private when you have a very localized issue and only want input from your neighborhood.
                </p>
                <p><i>City-wide issue:</i> This setting is only visible to administrators and is used by CCNA to display a topic across all neighborhoods.
                </p>
                <p><i>End date:</i> This optional field is used when you want to set a day limit for folks to comment.  Once a day
                  the system will set active topics that are beyond their "end date" to "inactive". </p>
                <p><i>Approve and Oppose:</i>These are a few words to describe what it means to approve or oppose the topic.  It helps avoid ambiguities.
                </p>
              </li>
              <li><b>Select an image:</b> As an option, you can select an image to be shown on the topic.
                <img src={imageAddTopic2} alt="adding topic" className="instructionPageImage" />
                <p>The images cannot be larger than 5 mb and they must be one of the following types: jgp,jpeg,png, or gif.</p>
              </li>
              <li><b>Choose recipients:</b> Recipients are the individuals that will receive public feedback
                on a weekly email sent by Sarasota Public.   Choose as many recipients as desired.
                <img src={imageAddTopic3} alt="adding topic-recipients" className="instructionPageImage" />
                <p>
                Click <a href={baseURL + '#/emailDescription'} target="_"  >here</a> for information about the weekly email.
                </p>
              </li>
            </ol>
          </div>

          <h4 id="id_editingtopic">Editing Topics</h4>
          <div>
            Here you can modify both active and inactive topics.  For topics that have no public input, you have the option to delete a topic.  
            Topics with public input can only be set to inactive (rather than deleting), so the public input does not get erased.
          </div>

          <h4 id="id_reviewsubmissions">Review Submissions</h4>
          <div>
            You can view all the public input for a selected topic.  Both active and inactive topics can be selected.  
            This feature allows you to export the public comments (which is great for documenting petitions).  The page also shows the current tally for public sentiment for the topic.
          </div>
          <h4 id="id_givesupport">Give Support</h4>
          <div>
            With this feature, neighborhoods can show their support for another neighborhood's topics.  Your neighborhood
            will appear in topic as supporting the topic.  The Sarasota Public email will also indicate the number of
            neighborhoods supporting an issue.
            <img src={imageGiveSupport} alt="adding topic-recipients" className="instructionPageImage" />
            <p>Note: administrators that are not assigned to a neighborhood will not see this feature.
            </p>
          </div>
          {(isAdministrator !== '0') && <>
            <i>The following features are only shown to administrators...</i>
            <h4 id="id_regenerateemail">Regenerate Email</h4>
            <div>
              Although the system will automatically generate emails once a week, there are occasions when you
              may want to regenerate an email.  One example is if a City Commissioner asks for prior comments for a topic.
              This feature allows you to chose a recipient (or all) and the number of prior days to include.  The system will
              then send a new email to the recipient with public comments from all topics assigned to them for the days you select.
            </div>
            <h4 id="id_emaildeliverylog">Email Delivery Logs</h4>
            <div>
              Sarasota Public uses the "SendGrid" service from Twilio to reliably send emails.  This menu takes you to the Twilio SendGrid main page.
              The "activity" subpage on Twilio's site has the status of each email attempted to delivered by Sarasota Public.
            </div>
            <h4 id="id_cronlog">Scheduled Task Log</h4>
            <div>
              This will display the text file that is written to whenever a cron task (aka:scheduled automated task on the webserver) is run.  The tasks are appended to the end 
              of the file but this page is shown in reverse chronological order.
            </div>
            <h4 id="id_adduser">Add User</h4>
            <div>
              Use this feature to create new users.  Note: only individuals with "administrator" permissions can be set to "all neighborhoods".
              See <a href="#typesOfUsers" onClick={(e) => scrollToSection(e, 'id_typesOfUsers')}>types of users</a> for more information about the levels of permissioning.
            </div>
            <h4 id="id_edituser">Edit Users</h4>
            <div>
              This screen gives you the ability to modify or delete existing users.
            </div>
            <h4 id="id_addrecipient">Add Recipient</h4>
            <div>
            This page allows you to create new email recipients.  The system does check whether the same email has been used for another recipient,
             to avoid multiple emails being sent to the same address.
            Click <a href={baseURL + '#/emailDescription'} target="_"  >here</a> for information about the weekly email.
            </div>
            <h4 id="id_editrecipient">Edit Recipients</h4>
            <div>
              This screen gives you the ability to modify or delete existing recipients.  All references to a deleted recipient are removed
              from any topic that the recipient was assigned.
            </div>
          </>}

        </Paper></div>
    </>
  );
}

export default PageInstructions;