import React, { createContext, useState } from 'react';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  // Set initial values for userID and neighborhoodSK
  const [userID, setUserID] = useState(null);
  const [neighborhoodSK, setNeighborhoodSK] = useState(null);
  const [neighborhoodName, setNeighborhoodName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isAdministrator, setIsAdministrator] = useState(null);
  const baseURL = 'https://sarasotapublic.com/';

  // Create a function to update the userID and neighborhoodSK
  const login = (newUserID, newNeighborhooodSK ,newIsAdministrator ,newNeighborhoodName, newUserName) => {
    setUserID(newUserID);
    setNeighborhoodSK(newNeighborhooodSK);
    setNeighborhoodName(newNeighborhoodName);
    setIsAdministrator(newIsAdministrator);
    setUserName(newUserName);
  };

  // Create a function to clear the userID and neighborhoodSK
  const logout = () => {
    setUserID(null);
    setNeighborhoodSK(null);
    setNeighborhoodName(null);
    setIsAdministrator(null);
    setUserName(null);
  };

  // Provide the values and functions to the children components
  return (
    <AuthContext.Provider value={{ userID, neighborhoodSK, isAdministrator, baseURL, neighborhoodName, userName, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};