import { React, useState, useEffect, useRef, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { Editor } from '@tinymce/tinymce-react';
import { MySelect } from './formFunction';
import postData from './postData';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { AuthContext } from './AuthContext';

const IssueAddFormStep1 = (props) => {
  const [fullNameValue, setFullNameValue] = useState(props.fullName);
  const [neighborhoodSKValue, setNeighborhoodSKValue] = useState(props.neighborhoodSK);
  const [isCitywideValue, setIsCityWideValue] = useState(props.isCitywide);
  const [isActive, setIsActive] = useState(props.isActive);
  const [isPrivate, setIsPrivate] = useState(props.isPrivate);
  const [dateEnd, setDateEnd] = useState(dayjs(props.dateEnd));
  const [approveDescription, setApproveDescription] = useState(props.approveDescription);
  const [disapproveDescription, setDisapproveDescription] = useState(props.disapproveDescription);
  const [bodyValue] = useState(props.body);

  //vars supporting combo box
  const [rsCombo, setrsCombo] = useState(undefined);
  const [isSelectDataFetched, setisSelectDataFetched] = useState(false);
  const [isNeighborhoodComboDisabled, setIsNeighborhoodComboDisabled] = useState(false);

  //vars supporting warning dialog
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const { baseURL, isAdministrator, neighborhoodSK } = useContext(AuthContext);
  const editorRef = useRef(null); //reference to MCE editor
  // ----------------------------------------------------

  IssueAddFormStep1.getData = () => {
    let theBody = "";
    if (editorRef.current) {
      theBody = editorRef.current.getContent();
    }
    return {
      fullName: fullNameValue,
      neighborhoodSK: neighborhoodSKValue,
      isCitywide: isCitywideValue,
      isActive: isActive,
      isPrivate: isPrivate,
      body: theBody,
      approveDescription: approveDescription,
      disapproveDescription: disapproveDescription,
      dateEnd: (dateEnd.isValid()) ? dateEnd.toISOString() : ""
    };
  };

  // ----------------------------------------------------
  // get neighborhood list from server
  useEffect(() => {
    const getSelectOptions = async () => {
      const formData = new FormData();
      var objSelect = await postData(baseURL + 'neighborhoodSelect.php', formData);
      setErrorMessage(objSelect.theError);
      if (objSelect.theData != null) {
        setrsCombo(objSelect.theData);
        setisSelectDataFetched(true);
        //if not an administrator, then set neighborhood to user's neighborhood
        if (isAdministrator !== '1') {
          setNeighborhoodSKValue(neighborhoodSK);
          setIsNeighborhoodComboDisabled(true);
        }

      }
    }
    if (rsCombo === undefined) {
      getSelectOptions();
    }
  })

  // ----------------------------------------------------
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  // ----------------------------------------------------

  return (<>
    {isSelectDataFetched === false && <Loadingalert />}
    {isSelectDataFetched &&
      <Paper elevation={10}>
        <Formik
          initialValues={{
            fullName: '',
            approveDescription: '',
            disapproveDescription: ''
          }}
        >
          <Form style={{ textAlign: "left", padding: 10 }}>

            <label htmlFor="fullName" style={{ marginTop: "0" }}>Title</label>
            <div style={{ display: "flex", flexDirection: "row", alignContent: "baseline", justifyContent: "space-between", width: "80%" }}>
              <Field
                name="fullName"
                type="text"
                value={fullNameValue}
                onChange={(e) => setFullNameValue(e.target.value)}
                style={{ width: "70%" }}
                required
              />
              <label style={{ paddingBottom: "10px", marginLeft: "20px" }}>
                <Field type="checkbox" name="isActive" checked={isActive}
                  onChange={(e) => setIsActive(!isActive)} />
                Is Active
              </label>
              {(isCitywideValue === false) &&
                <label style={{ paddingBottom: "10px", marginLeft: "20px" }} htmlFor="chkIsPrivate">
                  <Field type="checkbox" name="isPrivate" id="chkIsPrivate" checked={isPrivate}
                    onChange={(e) => setIsPrivate(!isPrivate)} />
                  Is Private
                </label>
              }

            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%" }}>
              <MySelect label="Raised by" name="neighborhoodSK"
                id="neighborhoodSKCombo"
                value={neighborhoodSKValue}
                disabled={isNeighborhoodComboDisabled}
                onChange={(e) => setNeighborhoodSKValue(e.target.value)} >
                <option key="0" value="0">Select a neighborhood</option>
                {(rsCombo) && rsCombo.map((option) => (
                  <option key={option.sk} value={option.sk}>
                    {option.fullName}
                  </option>))}
              </MySelect>
              {(isAdministrator === '1') &&
                <label style={{ marginBottom: "25px", marginTop: "50px", marginLeft: "20px" }}>
                  <Field type="checkbox" name="isCityWide" checked={isCitywideValue}
                    onChange={(e) => setIsCityWideValue(!isCitywideValue)} />
                  City-wide issue
                </label>}
              <div>
                <label style={{ marginTop: "50px", marginLeft: "20px" }} >
                  End Date
                  <DatePicker
                    sx={{ width: "200px", marginLeft: "5px", position: "relative", top: "-12px" }}
                    value={dateEnd}
                    onChange={(newValue) => setDateEnd(newValue)}
                  />
                </label>
              </div>
            </div>

            <div style={{ marginBottom: "25px", display: "flex", flexDirection: "row", justifyContent: "space-between", width: "80%" }}>
              <div style={{ display: "flex", flexDirection: "column", paddingRight: "10px" }}>
                <label htmlFor="approveDescription" style={{ marginTop: "0" }}>Approve</label>
                <Field
                  name="approveDescription"
                  type="text"
                  placeholder="brief description what it means to approve"
                  value={approveDescription}
                  onChange={(e) => setApproveDescription(e.target.value)}
                  style={{ width: "30vw" }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <label htmlFor="disapproveDescription" style={{ marginTop: "0" }}>Oppose</label>
                <Field
                  name="disapproveDescription"
                  type="text"
                  placeholder="brief description what it means to oppose"
                  value={disapproveDescription}
                  onChange={(e) => setDisapproveDescription(e.target.value)}
                  style={{ width: "30vw" }}
                />
              </div>
            </div>
            <Editor
              apiKey='o0ciiwymd8qsbwojphyi3fsuk7s3smpby7wouwbpat98a246'
              onInit={(evt, editor) => editorRef.current = editor}
              initialValue={bodyValue}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview',
                  'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                  'insertdatetime', 'media', 'table', 'code',
                ],
                toolbar: 'undo redo | fontfamily fontsize | ' +
                  'bold italic link | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | forecolor | ',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                contextmenu: 'undo redo | cut copy paste pastetex image table | link'
              }}
            />


          </Form>
        </Formik></Paper>}
    <Dialog
      open={openWarningDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Neighborhood Retrieval Error
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {errorMessage}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogOK}>Ok</Button>
      </DialogActions>
    </Dialog>
  </>);
}

export default IssueAddFormStep1;