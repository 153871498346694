import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { Button, Input,Paper } from '@mui/material';

const IssueAddFormStep2 = (props) => {

  const [imageFile, setImageFile] = useState(null);
  const [theImage, setTheImage] = useState(null);
  const [clearImageFlag, setClearImageFlag] = useState(false);

  //--------------------------------------------------------------
  IssueAddFormStep2.getData = () => {
    return {
      theFile: imageFile,
      clearImage: clearImageFlag
    };
  };


  useEffect(() => {
    if (theImage === null) {
      setTheImage(props.image);
    }
  }// eslint-disable-next-line
  ,[]
  )

  //--------------------------------------------------------------

  const handleFileSelection = (event) => {
    const file = event.currentTarget.files[0];
    setImageFile(file);
    setClearImageFlag(false);
    if (file !== undefined) {
      const imageUrl = URL.createObjectURL(file);
      setTheImage(imageUrl);
    } else {
      setTheImage('');
    }
  };

  
  const handleClearImage = (event) => {
    setClearImageFlag(true);
    setImageFile(null);
    setTheImage('');
  };
  //--------------------------------------------------------------
  return (
    <Paper elevation={10}><div style={{padding:10}}>
      <img src={theImage}  alt="....None...." style={{ maxWidth: '40%', border: '5px solid #2b9ed4' }} />
      <Formik

        initialValues={{
          file: null
        }}
      >
        <form style={{ textAlign: 'left' }}>
          {/* form fields */}

          <Input
            name="imageFile"
            id="imageFile"
            type="file"
            onChange={handleFileSelection}
            style={{ display: 'none' }}
          />
          <label htmlFor="imageFile" style={{ verticalAlign: 'middle' }}>
            <p style={{ marginRight: '10px', marginTop: '5px' }}>Change Image</p>
            <Button variant="contained" component="span" style={{ height: '30px', fontSize: '9pt' }}>
              New File
            </Button> {imageFile && <span style={{ marginLeft: '10px', marginTop: '5px' }}>{imageFile.name}</span>}
          </label>

          <span style={{ marginRight: '10px', marginTop: '5px' }}><strong>Remove Image</strong></span>
          <Button variant="outlined" onClick={() => handleClearImage()} component="span" style={{ height: '30px', fontSize: '9pt' }}>
            Clear
          </Button>
        </form>
      </Formik></div>
    </Paper>
  );
};

export default IssueAddFormStep2;