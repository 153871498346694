import { React, useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import postData from './postData';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from './AuthContext';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';



const RecipientAddForm = () => {

  const { sk, fullNameOld, emailOld } = useParams();  //get parameter from router
  const { baseURL, isAdministrator } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveProcessStarted, setIsSaveProcessStarted] = useState(false);
  const history = useHistory();

  //vars supporting other fields
  const [fullNameValue, setFullNameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");

  //vars supporting warning dialog
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // ----------------------------------------------------
  // make sure the user is an administrator, if not send them back to the home back
  useEffect(() => {
    if (isAdministrator === null || isAdministrator === false) {

      history.push("/");
    }
    //initialize variables
    if (sk !== '0') {
      setFullNameValue(fullNameOld);
      setEmailValue(emailOld);
    }
    
    // eslint-disable-next-line
  }, [isAdministrator, history]);

  // ----------------------------------------------------
  // ----- handle user warning

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  const clickCancel = () => {
    history.push("/recipientEdit");
  };

  // ----------------------------------------------------
  const trySavingData = async () => {
    const formData = new FormData();;
    formData.append('email', emailValue);
    formData.append('fullName', fullNameValue);
    formData.append('sk', sk);

    var objData = await postData(baseURL + 'addressbookAddEdit.php', formData);

    if (objData.theError !== "") {
      setIsSubmitting(false);
      setErrorMessage(objData.theError);
    } else {
      if (objData.theData != null) {
        history.push("/recipientEdit");
      }
    } //else-error
  } //trySavingData

  useEffect(() => {
    if (isSubmitting === true) {
      //verify the form fields
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
        setIsSubmitting(false);
        setErrorMessage("The email address is not valid");
        return;
      }
    }
    if (isSubmitting === true && isSaveProcessStarted === false) {
      trySavingData();
      setIsSaveProcessStarted(true);
    }
    //eslint-disable-next-line
  }, [isSubmitting, trySavingData]
  )

  // ----------------------------------------------------
  return (
    <>
      {(sk === '0') && <h2>Adding a Recipient</h2>}
      {(sk !== '0') && <h2>Editing a Recipient</h2>}
      <Formik
        initialValues={{
          userFullName: '',
          userPassword: ''
        }}
        onSubmit={async (values) => {
          setIsSubmitting(true);
        }}
      >
        <Form style={{width:'80vw'}}>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ float: 'left' }}>
              <div style={{ marginBottom: '25px' }}>
                <label htmlFor="txtheading">Name</label>
                <Field
                  name="txtName"
                  id="txtheading"
                  type="text"
                  value={fullNameValue}
                  onChange={(e) => setFullNameValue(e.target.value)}
                  style={{ width: "70vw" }}
                  required
                />
              </div>              
              <div style={{ marginBottom: '25px' }}>
                <label htmlFor="txtUserEmail" >Email</label>
                <Field
                  name="txtUserEmail"
                  id="txtUserEmail"
                  type="text"
                  value={emailValue}
                  onChange={(e) => setEmailValue(e.target.value)}
                  style={{ width: "70vw" }}
                  required
                />
              </div>
              <div className='formButtonContainer'>
                <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting} variant='contained'>Save</LoadingButton>
                <Button variant='outlined' onClick={() => clickCancel()} disabled={isSubmitting}>Cancel</Button>

              </div>
            </div></div>
        </Form>
      </Formik>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RecipientAddForm;