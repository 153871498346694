import { Paper } from "@mui/material";
import imgFeatures from "./images/emailFeatures.png";
import imgRespond from "./images/emailRespond.png";

const PageEmailDescription = () => {
  return (<>
    <h2>Sarasota Public Email Features</h2>
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <Paper elevation={12} sx={{ textAlign: 'left', padding: '20px', width: '80vw' }}>

        <p>The emails from Sarasota Public have been designed to provide a wealth of information, not available in ordinary emails, which makes following public sentiment easier and thus public input more meaningful.</p>
        <p> The system gathers community input throughout the week and generates one email for all the topics with the activity during the week.</p><p>In addition (as shown in the sample below), it includes a running total of all the comments as well as the ability to look at the text of the topic.The leading prevaling sentiment is highlighted in color (dispproval in <span style={{ color: 'var(--colorDisapprove)' }}>red</span> for the sample below).
          <img alt="selecting a topic" src={imgFeatures} className="emailDescriptionPageImage" />
        </p>


        <h5>Replying to a comment...</h5>
        <p>Each comment includes a "Respond" button.  Clicking on that will open up a blank email on your device, while filling in the individual's email address. <img alt="selecting a topic" src={imgRespond} className="emailDescriptionPageImage" /></p>

      </Paper>
    </div>
  </>);
}

export default PageEmailDescription;