import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from './AuthContext';
import { Paper } from '@mui/material';
import Loadingalert from './LoadingAlert';

const PageCronLog = () => {

  const [aryContent, setContentArray] = useState('');
  const { baseURL } = useContext(AuthContext);

  useEffect(() => {
    // Fetch the content from the URL when the component mounts
    fetch(baseURL + 'cronlog.txt')
      .then((response) => response.text())
      .then((data) => {
        let aryTemp = data.split('\n');
        if (aryTemp.length > 0) {
          if (aryTemp[aryTemp.length - 1] === '') {
            //eslint-disable-next-line 
            let _dummy = aryTemp.pop();  //extra line feed at the end
          }
          let aryFinal = aryTemp.reverse();
          setContentArray(aryFinal);
        } else {
          setContentArray('empty log file');
        }
      })
      .catch((error) => {
        console.error('Error fetching URL content:', error);
      });
    //eslint-disable-next-line 
  }, []);


  return (<>
    <h2>Scheduled Task Log</h2>
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <Paper elevation={12} sx={{ textAlign: 'left', padding: '20px', width: '80vw' }}>
        {(aryContent === '') && <Loadingalert />}
        {(aryContent !== '') &&
          <ul>
            {aryContent.map((line, index) => (
              <li key={index}>{line}</li>
            ))}
          </ul>}
      </Paper>
    </div>

  </>);
}

export default PageCronLog;