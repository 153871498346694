import React, { useState, useEffect, useContext, useRef, useMemo, useCallback } from 'react';
import { Formik, Form, } from 'formik';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, RadioGroup, Radio, FormControlLabel  } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles-custom.css'; //referenced after core styles for ag-grid
import SeparatorIcon from '@mui/icons-material/Circle';


function IssueListSubmission() {
  const { baseURL, isAdministrator, neighborhoodSK, neighborhoodName } = useContext(AuthContext);
  const [aryIssue, setIssueArray] = useState(undefined);
  const [isArrayInitialized, setIsArrayInitialized] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const [issueSK, setIssueSK] = useState(0);


  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  // submission grid variables
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  
  const [aryComment, setCommentArray] = useState(undefined);
  const [retryCountComment, setRetryCountComment] = useState(0);

  const [approvePct , setApprovePct] = useState(null);
  const [disapprovePct , setDisapprovePct] = useState(null);
  const [neutralPct , setNeutralPct] = useState(null);
  const txtSeparator = <>&nbsp;<SeparatorIcon sx={{color:'#2b9ed4',padding:'6px',position:'relative',top:'6px'}}/>&nbsp;</>;
  

  const columnDefs = [
    { headerName: 'Count', field: 'id' },
    { headerName: 'Name', field: 'fullName' },
    { headerName: 'Date', field: 'dateComment' },
    {
      headerName: 'Opinion', field: 'opinion'
      , cellStyle: params => {
        if (params.value === 'approve') {
          return {'color':'var(--colorApprove)'};
        }
        if (params.value === 'disapprove') {
          return {'color':'var(--colorDisapprove)'};
        }
        if (params.value === 'neutral') {
          return {'color':'var(--colorNeutral)'};
        }
        return null;
      }
    },
    { headerName: 'Comment', field: 'body', width:400},
    { headerName: 'Residency', field: 'residency'},
    { headerName: 'Email' ,field: 'email'},
    { headerName: 'IP Address' ,field: 'ipAddress'}
  ];

  const defaultColDef = {
    sortable: true, 
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressMovable : true
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  const onBtnExport = useCallback(() => {
    gridRef.current.api.exportDataAsCsv();
  }, []);


  //-------------------------------------------------------------  
  // retrieve relevant submissions ------------------------------
  const getSubmissions = async () => {
    if (issueSK !== 0) {
      const formData = new FormData();
      formData.append('issueSK', null);
      formData.append('issueSK', issueSK);
      var objEntity = await postData(baseURL + 'issueCommentSelect.php', formData);
      setErrorMessage(objEntity.theError);
      if (objEntity.theData !== null) {
        setCommentArray(objEntity.theData);
        //check size of the array, when returning to first combo option (Select)
        //the array will be zero since passing in issueSK = 0
        if (objEntity.theData.length > 0) {
          let numApprove = parseInt( objEntity.theData[0]["approveCount"]);
          let numDisapprove = parseInt(objEntity.theData[0]["opposeCount"]);
          let numNeutral = parseInt(objEntity.theData[0]["neutralCount"]);
          let numTotal = numApprove + numDisapprove + numNeutral;
          setApprovePct(Math.round(numApprove /numTotal * 100));
          setDisapprovePct(Math.round(numDisapprove /numTotal * 100));
          setNeutralPct(Math.round(numNeutral /numTotal * 100));  
        }

      } else {
        if (retryCountComment < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCountComment(retryCountComment + 1), 3000);
        } //retry
      } //else 
    }
  }
  useEffect(() => {
    getSubmissions();
    // eslint-disable-next-line
  }, [retryCountComment]);

  useEffect(() => {
    setCommentArray(null);
    setApprovePct(null);
    setRetryCountComment(0);
    getSubmissions();
    // eslint-disable-next-line
  }, [issueSK]);

  //-------------------------------------------------------------
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleErrorDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //-------------------------------------------------------------
  // get the topic list from the server

  const getIssueList = async () => {
    if (isArrayInitialized === false) {
      const formData = new FormData();
      formData.append('issueSK', null);
      formData.append('isActive', isActive);
      let $neighborhoodSKEffective = neighborhoodSK;
      //for administrators, show all issues
      if (isAdministrator === '1') {
        $neighborhoodSKEffective = null;
      };
      formData.append('neighborhoodSK', $neighborhoodSKEffective);
      var objEntity = await postData(baseURL + 'issueSelect.php', formData);
      setErrorMessage(objEntity.theError);
      if (objEntity.theData !== null) {
        setIssueArray(objEntity.theData);
        setIsArrayInitialized(true);
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 
    }
  }
  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [retryCount]);

  useEffect(() => {
    setIsArrayInitialized(false);
    setRetryCount(0);
  }, [isActive]);

  useEffect(() => {
    getIssueList();
    // eslint-disable-next-line
  }, [isArrayInitialized]);

  //-------------------------------------------------------------

  return (
    <>
      <div className="searchable-list">
        {(isAdministrator === '1') && <h3>Submissions for all Topics</h3>}
        {(isAdministrator !== '1') && <h3>Submissions for {neighborhoodName} Topics</h3>}
        <br />

        <Formik
        >
          <Form>
            <div align="left" style={{ paddingBottom: '20px', display: "flex", flexDirection: "row" }}>
              <div style={{display:"flex" ,flexDirection:"column"}}>
                <label htmlFor="comboIssue">Topic</label>
                <select
                  style={{width:"60vw"}}
                  name="cboIssue"
                  id="comboIssue"
                  value={issueSK}
                  onChange={(e) => setIssueSK(e.target.value)} >
                  <option key="0" value="0">Select a Topic</option>
                  {(aryIssue) && aryIssue.map((option) => (
                    <option key={option.sk} value={option.sk}>
                      {option.fullName}
                    </option>))}
                </select>
              </div>
              <RadioGroup
                name="isActiveRadio"
                value={isActive}
                onChange={(event) => setIsActive(event.target.value)}
                row
                sx={{ paddingTop: '40px' }}
                required
              >
                <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="1" value={true} control={<Radio sx={{ padding: '1px' }} />} label="Active" />
                <FormControlLabel sx={{ padding: '1px', marginTop: '0' }} key="0" value={false} control={<Radio sx={{ padding: '1px' }} />} label="Inactive" />
              </RadioGroup>
            </div>
          </Form>
        </Formik>
        {(aryIssue === undefined) && <Loadingalert />}
        {(aryComment !== null) &&
          <>
            <div style={{ display: 'flex', flexFlow: 'column', alignItems: 'flex-start'}} >
              <div style={{ margin: '10px 0' ,display:'flex' ,justifyContent:'space-between',width:'100%' }}>
              {(approvePct !== null) &&
                <div style={{ marginLeft: '20px' }}>
                  <span className='approveCount'>Approve: {approvePct}%</span>{txtSeparator}<span className='disapproveCount'>Disapprove {disapprovePct}%</span>{txtSeparator}<span className='neutralCount'>Neutral {neutralPct}%</span>
                </div>}
                <Button variant='contained' onClick={onBtnExport} >Download CSV export file</Button>
              </div>
              <div id="gridContainer" style={{ height: '60vh', width: '100%' ,textAlign:'left' }}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <AgGridReact
                    ref={gridRef}
                    rowData={aryComment}
                    suppressExcelExport={true}
                    popupParent={popupParent}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                  />
                </div>
              </div>
            </div>
          </>}
      </div>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Issue Deletion Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default IssueListSubmission;