import { React } from 'react';
import IssueListComment from './issueListComment';
import { useParams } from 'react-router-dom';

const Home = () => {

  var v_neighborhookSK = 0;

  const {sk } = useParams();  //get parameter from router

  if (sk !== undefined) {
    v_neighborhookSK = sk;
  }
    return (

        <div className="home" >
            <h3>Having your voice heard...</h3>
            <br />
            <IssueListComment neighborhoodSK= {v_neighborhookSK} />
        </div>
    );
}

export default Home;