import { React} from 'react';

// ----------------------------------------------------
// error page
// ----------------------------------------------------
const PageNotFound
    = () => {

        //   )
        return (
            <div>
                <h3>Page not found</h3>
            </div>
        );
    };


export default PageNotFound;
