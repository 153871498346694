import './App.css';
import { React, useRef } from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import NavAppBar from './navAppBar';
import { createTheme, ThemeProvider } from '@mui/material';
import { AuthProvider } from './AuthContext';
import Home from './home';
import PageNotFound from './pageNotFound';
import IssueAddForm from './issueAddForm';
import IssueListEdit from './issueListEdit';
import IssueCommentForm from './issueCommentForm';
import EmailGenerateForm from './emailGenerateForm';
import UserLoginForm from './userLoginForm';
import UserAddForm from './userAddForm';
import UserListEdit from './userListEdit';
import IssueListSupport from './issueListSupport';
import IssueListSubmission from './issueListSubmission';
import RecipientListEdit from './recipientListEdit';
import RecipientAddForm from './recipientAddForm';
import UserChangePasswordForm from './userChangePasswordForm';
import PageAbout from './pageAbout';
import PageEmailDescription from './pageEmailDescription';
import PagePrivacyPolicy from './pagePrivacyPolicy';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import PageInstructions from './pageInstructions';
import PageCronLog from './pageCronLog';
import HomeNeighborhood from './homeNeighborhood';

function App() {

  const divRef = useRef();
  //eslint-disable-next-line
  const handleClick = () => {
    console.log(divRef.current.outerHTML);
  };

  const theme = createTheme({
    components: {
      // Name of the component
      MuiButton: {
        styleOverrides: {
          // Name of the slot
          contained: {
            // contained button
            backgroundColor: '#2b9ed4',
            '&:hover': { backgroundColor: '#98d0ea' }
          },
          outlined: {
            color: '#2b9ed4',
            borderColor: '#2b9ed4',
            '&:hover': { backgroundColor: '#def0f8', borderColor: '#98d0ea' }
          }
        },
      },
      MuiAvatar: {
        variants: [
          { props: { className: 'entityType-list' }, style: { backgroundColor: '#2BA0F0', width: '20px', height: '20px', fontSize: '8pt' } }
          , { props: { className: 'entityType-flow' }, style: { backgroundColor: '#2BA0F0', width: '16px', height: '16px', fontSize: '6pt' } }
          , { props: { className: 'userWelcomeStep' }, style: { backgroundColor: '#f1356d', width: '16px', height: '16px', fontSize: '8pt' } }
        ],
      }
    },
  });

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div className="App" ref={divRef}>
              <NavAppBar />
              <div className="content">
                <ThemeProvider theme={theme}>
                  <Switch>
                    <Route exact path="/"><Home /></Route>
                    <Route path="/sarasotapublic"><Redirect to="/" /></Route>
                    <Route path="/about"><PageAbout /></Route>
                    <Route path="/cronLog"><PageCronLog /></Route>
                    <Route path="/emailDescription"><PageEmailDescription /></Route>
                    <Route path="/emailGenerate"><EmailGenerateForm /></Route>
                    <Route path="/neighborhoodView/:sk"><HomeNeighborhood /></Route>
                    <Route path="/instructions"><PageInstructions /></Route>
                    <Route path="/issueAdd/:sk/:title"><IssueAddForm /></Route>
                    <Route path="/issueComment/:sk"><IssueCommentForm /></Route>
                    <Route path="/issueEdit"><IssueListEdit /></Route>
                    <Route path="/issueSubmission"><IssueListSubmission /></Route>
                    <Route path="/issueSupport"><IssueListSupport /></Route>
                    <Route path="/privacy"><PagePrivacyPolicy /></Route>
                    <Route path="/recipientEdit"><RecipientListEdit /></Route>
                    <Route path="/recipientAdd/:sk/:fullNameOld/:emailOld"><RecipientAddForm /></Route>
                    <Route path="/userAdd/:sk"><UserAddForm /></Route>
                    <Route path="/userChangePassword"><UserChangePasswordForm /></Route>
                    <Route path="/userEdit"><UserListEdit /></Route>
                    <Route path="/userLogin"><UserLoginForm /></Route>
                    <Route component={PageNotFound} />
                  </Switch>
                </ThemeProvider>
              </div>
            </div>
          </LocalizationProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
