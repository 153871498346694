import { React, useState, useEffect ,useContext } from 'react';
import { Formik, Form } from 'formik';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { MyTextInput } from './formFunction';
import { useHistory } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import postData from './postData';

// ----------------------------------------------------

const UserChangePasswordForm = () => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveProcessStarted, setIsSaveProcessStarted] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userPasswordValue, setUserPasswordValue] = useState("");
  
  const { baseURL ,userID } = useContext(AuthContext);

  let history = useHistory();
  // ----------------------------------------------------

  const clickCancel = () => {
    history.push("/");
  };
  // ----------------------------------------------------

  const trySavingData = async () => {
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('userPassword', userPasswordValue);
    formData.append('appUserSK',userID);

    var objData = await postData(baseURL + 'appUserChangePassword.php', formData);
    if (objData.theError === "") {
      history.push('/');
    } else {
      setErrorMessage(objData.theError);
    }
  } //trySavingData

  useEffect(() => {
    if (isSubmitting === true && isSaveProcessStarted === false) {
      trySavingData();
      setIsSaveProcessStarted(true);
    }
    //eslint-disable-next-line
  }, [isSubmitting, trySavingData]
  )
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setIsSubmitting(false);
    setIsSaveProcessStarted(false);
    setErrorMessage('');
  };
    // ----------------------------------------------------

    return (<>
      <h2>Changing Password</h2>
      <Formik
        initialValues={{
          userPassword: ''
        }}
        onSubmit={async (values) => {
          setIsSubmitting(true);
        }}
      >
        <Form>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ float: 'left' }}>

              <MyTextInput
                label="New Password"
                name="userPassword"
                type="password"
                value={userPasswordValue}
                onChange={(e) => setUserPasswordValue(e.target.value)}
                required
              />
              <div className='formButtonContainer' style={{marginTop:'30px'}}>
                <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting} variant='contained'>Save</LoadingButton>
                <Button variant='outlined' onClick={() => clickCancel()} disabled={isSubmitting}>Cancel</Button>
              </div>
            </div></div>
        </Form>
      </Formik>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Login error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>);
  }

  export default UserChangePasswordForm;