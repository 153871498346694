import React, { useState, useEffect, useContext, useMemo,useRef  } from 'react';
import { useHistory } from 'react-router-dom';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Tooltip } from '@mui/material';
import postData from './postData';
import { AuthContext } from './AuthContext';
import DeleteIcon from '@mui/icons-material/Clear';
import { Check as CheckIcon } from '@mui/icons-material';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './styles-custom.css'; //referenced after core styles for ag-grid

function UserListEdit() {
  const { baseURL } = useContext(AuthContext);
  const history = useHistory();
  const [internalError, setInternalError] = useState('');
  const [aryUser, setUserArray] = useState(undefined);
  const [aryUserFiltered, setUserArrayFiltered] = useState(undefined);
  const [isArrayInitialized, setIsArrayInitialized] = useState(false);
  const [query, setQuery] = useState('');
  const [retryCount, setRetryCount] = useState(0);

  //vars to handle delete
  const [deleteSK, setDeleteSK] = useState(undefined);
  const [deleteText, setDeleteText] = useState(undefined);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDataReset, setIsDataReset] = useState(false);

  //warning dialog variables
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  var bIsInitialized = false;
  //-------------------------------------------------------------  
// user grid variables
  const gridRef = useRef();

  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const columnDefs = [
    {
      headerName: '', field: 'sk', colId: 'deleteCol', flex :1, cellRenderer: params => {
        return <Tooltip title="delete the user"><DeleteIcon sx={{ color: 'var(--colorDisapprove)', padding: '5px' }} /></Tooltip>;
      }
    },
    { headerName: 'Name', field: 'userFullName', flex: 5},
    { headerName: 'Email', field: 'email', flex: 5 },
    { headerName: 'Admin', field: 'isAdministrator',flex: 1.2,  cellRenderer: params => {
      if (params.data.isAdministrator === '1') {
        return <CheckIcon/>;
      }      
    }
  },
  { headerName: 'Neighborhood', field: 'neighborhoodName', flex: 3 }
  ];

  const defaultColDef = {
    sortable: true, 
    wrapText: true,
    autoHeight: true,
    resizable: true,
    suppressMovable : true
  };

  const popupParent = useMemo(() => {
    return document.body;
  }, []);


  const doCellClicked = (params) => {
    //handle click in data grid
    //check to see if it's in the delete column, if so handle delete
    //otherwise go to editing the user
    if (params.colDef.colId === "deleteCol") {
      handleItemDelete(params.data.sk, params.data.userFullName);
    } else {
      handleItemClick(params.data.sk, params.data.fullName);
    }
  };
  //-------------------------------------------------------------
  // handle list selection

  const handleItemClick = (item) => {
    history.push(`/userAdd/${item}`);
  };

  //-------------------------------------------------------------
  // functions to handle delete functionality

  const handleItemDelete = (itemSK, itemTitle) => {
    setDeleteSK(itemSK);
    setDeleteText(itemTitle)
    setOpenDeleteDialog(true);
  };

 const handleDialogCancel = () => {
  setDeleteSK(undefined);
  setDeleteText(undefined)
  setOpenDeleteDialog(false);
}

  const handleDialogOK = async () => {
    // close the dialog
    setOpenDeleteDialog(false);

    if (deleteSK === undefined) {
      setErrorMessage("the sk to delete is undefined");
    } else {
      //save to database
      const formData = new FormData();
      formData.append('appUserSK', deleteSK);
      let objData = await postData(baseURL + 'appUserDelete.php', formData);
      if (objData.theError === "") {
        // refresh user list
        setIsDataReset(true);
      } else {
        setErrorMessage(objData.theError);
      }

    }
  }
  
useEffect(() => {
  if (isDataReset){
  // eslint-disable-next-line
    bIsInitialized = false;
    setUserArray(undefined);
    setRetryCount(retryCount - 1);
  }// eslint-disable-next-line
} ,[isDataReset])
  
  // ----- handle user warning
  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleErrorDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  //-------------------------------------------------------------
  // get the topic list from the server

  const getAppUserList = async () => {
      const formData = new FormData();
      formData.append('appUserSK', 0);
      var objEntity = await postData(baseURL + 'appUserSelect.php', formData);
      setInternalError(objEntity.theError);
      if (bIsInitialized === false) {
        if ((aryUser === undefined) && (objEntity.theData !== null)) {
        setUserArray(objEntity.theData);
        setUserArrayFiltered(objEntity.theData);
        setIsArrayInitialized(true);
        bIsInitialized = true;
        setIsDataReset(false);
        };
      } else {
        if (retryCount < 3) {
          // Retry after 3 seconds
          setTimeout(() => setRetryCount(retryCount + 1), 3000);
        } //retry
      } //else 
  }
  useEffect(() => {
    getAppUserList();
    // eslint-disable-next-line
  }, [retryCount]);

  useEffect(() => {
    getAppUserList();
    // eslint-disable-next-line
  }, [isArrayInitialized]);

  //-------------------------------------------------------------

  useEffect(() => {
    // filter the list when query changes
    if (aryUser === undefined) {
      return undefined
    } else {
      setUserArrayFiltered(aryUser.filter((row) => row.userFullName.toLowerCase().includes(query.toLocaleLowerCase())));
    }
  }, [query, aryUser])
  //-------------------------------------------------------------

  return (
    <>
      <div className="searchable-list">
        <h3>Select a user to edit</h3>
        <br />
        <div align="left" style={{ paddingBottom: '20px',display: "flex", flexDirection: "row"  }}>
          <input
            type="text"
            placeholder="Search..."
            id="searchbox"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {(aryUserFiltered === undefined) && <Loadingalert />}
        <div id="gridContainer" style={{ height: '70vh', width: '100%' ,textAlign:'left' }}>
                <div style={gridStyle} className="ag-theme-alpine">
                  <AgGridReact
                    ref={gridRef}
                    rowData={aryUserFiltered}
                    popupParent={popupParent}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}                    
                   onCellClicked={doCellClicked}
                  />
                </div>
              </div>
        {(internalError !== "" && retryCount > 2) && <div>Error:{internalError}</div>}
      </div>
      <Dialog
        open={openDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          User Deletion
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete the user '{deleteText}'?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogCancel}>Cancel</Button>
          <Button onClick={handleDialogOK}>Delete</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          User Deletion Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleErrorDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserListEdit;