import { React, useState, useEffect, useContext } from 'react';
import { Formik, Form, Field } from 'formik';
import { MySelect } from './formFunction';
import postData from './postData';
import Loadingalert from './LoadingAlert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { AuthContext } from './AuthContext';
import { useHistory ,useParams} from 'react-router-dom/cjs/react-router-dom.min';



const UserAddForm = () => {

  const {sk} = useParams();  //get parameter from router
  const { baseURL, isAdministrator } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSaveProcessStarted, setIsSaveProcessStarted] = useState(false);
  const history = useHistory();

  //vars supporting combo box
  const [rsCombo, setrsCombo] = useState(undefined);
  const [userNeighborhoodSK, setUserNeighborhoodSK] = useState(0);

  //vars supporting other fields
  const [userFullNameValue, setUserFullNameValue] = useState("");
  const [userPasswordValue, setUserPasswordValue] = useState("");
  const [userEmailValue, setUserEmailValue] = useState("");
  const [isUserAdministrator,setIsUserAdministrator] = useState(false);
  const [isShowPassword,setIsShowPassword] = useState(true);

  const [isInitialized, setIsInitialized] = useState(false);
  const [rsAppUser, setrsAppUser] = useState(undefined);  

  //vars supporting warning dialog
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState(''); 



  // ----------------------------------------------------
  // make sure the user is an administrator, if not send them back to the home back
  useEffect(() => {
    if (isAdministrator === null || isAdministrator === false) {

      history.push("/");
    }
  }, [isAdministrator, history]);

  // ----------------------------------------------------
  // get neighborhood list from server

  useEffect(() => {
    const getSelectOptions = async () => {
      const formData = new FormData();
      var objSelect = await postData(baseURL + 'neighborhoodSelect.php', formData);
      setErrorMessage(objSelect.theError);
      if (objSelect.theData != null) {
        setrsCombo(objSelect.theData);
      }
    }
    if (rsCombo === undefined) {
      getSelectOptions();
    }
  })

  // ----------------------------------------------------
// initialize the user values

const retrieveAppUserInfo = async () => {
  const formData = new FormData();
  formData.append('appUserSK', sk);
  var objData = await postData(baseURL + 'appUserSelect.php', formData);
  if (objData.theError === "") {
    setrsAppUser(objData.theData);
  } else {
    setErrorMessage(objData.theError);
  }
}

useEffect(() => {
  if (isInitialized === false) {
    if (sk === '0') {
      setUserFullNameValue('');
      setUserEmailValue('');
      setUserNeighborhoodSK(0);
      setIsUserAdministrator(false);
      setIsShowPassword(true);
    } else {
      retrieveAppUserInfo();
      setIsShowPassword(false);
    }
  }
  // eslint-disable-next-line
},[sk])

useEffect(() => {
  if (rsAppUser !== undefined) {
    setUserFullNameValue(rsAppUser[0].userFullName);
    setUserEmailValue(rsAppUser[0].email);
      setUserNeighborhoodSK(rsAppUser[0].neighborhoodSK);
      setIsUserAdministrator(rsAppUser[0].isAdministrator === '1'? true : false);
      setIsInitialized(true);
    }
},[rsAppUser])

useEffect(() => {
  setIsInitialized(false);
}, [sk])

  // ----------------------------------------------------
  // ----- handle user warning

  useEffect(() => {
    if (errorMessage !== '') {
      setOpenWarningDialog(true);
    }
  }, [errorMessage]
  )
  const handleDialogOK = () => {
    setOpenWarningDialog(false);
    setErrorMessage('');
  };
  const clickCancel = () => {
    history.push("/userEdit");
  };

  // ----------------------------------------------------
  const trySavingData = async () => {
    const formData = new FormData();
    formData.append('neighborhoodSK', userNeighborhoodSK);
    formData.append('userFullName', userFullNameValue);
    formData.append('passwordChangeFlag', (isShowPassword) ? 1 : 0);
    formData.append('userPassword', userPasswordValue);
    formData.append('email', userEmailValue);
    formData.append('isAdministrator',(isUserAdministrator)? 1:0);
    formData.append('appUserSK',sk);

    var objData = await postData(baseURL + 'appUserAddEdit.php', formData);

    if (objData.theError !== "") {
      setIsSubmitting(false);
      setErrorMessage(objData.theError);
    } else {
      if (objData.theData != null) {
        history.push("/userEdit");
      }
    } //else-error
  } //trySavingData

  useEffect(() => {
    if (isSubmitting === true) {
      //verify the form fields
      if (isUserAdministrator === false && userNeighborhoodSK === 0){
        setIsSubmitting(false);
        setErrorMessage("Only administrators can be set to all neighborhoods");
        return;
      }
       if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userEmailValue)){
        setIsSubmitting(false);
        setErrorMessage("The email address is not valid");
        return;
      } 
    }
    if (isSubmitting === true && isSaveProcessStarted === false) {
      trySavingData();
      setIsSaveProcessStarted(true);
    }
    //eslint-disable-next-line
  }, [isSubmitting, trySavingData]
  )

  // ----------------------------------------------------
  return (
    <>
      {(sk === '0') && <h2>Adding a User</h2>}
      {(sk !== '0') && <h2>Editing User</h2>}
      {rsCombo === undefined && <Loadingalert />}
      {rsCombo !== undefined &&
        <Formik
          initialValues={{
            userFullName: '',
            userPassword: ''
          }}
          onSubmit={async (values) => {
            setIsSubmitting(true);
          }}
        >
          <Form>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ float: 'left' }}>                
              <div style={{marginBottom:'25px'}}>
                <label htmlFor="txtheading">Name</label>
                <Field
                  name="txtName"
                  id="txtheading"
                  type="text"
                  value={userFullNameValue}
                  onChange={(e) => setUserFullNameValue(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
                </div>
                <div style={{marginBottom:'25px'}}>
                  {(isShowPassword === true) && <>                
                  <label htmlFor="txtUserPassword" >Password</label>
                <Field
                  name="txtUserPassword"
                  id="txtUserPassword"
                  type="password"
                  value={userPasswordValue}
                  onChange={(e) => setUserPasswordValue(e.target.value)}
                  style={{ width: "100%" }}
                  required
                /></>}
                {(sk !=='0' && isShowPassword === true) &&
                     <Button variant='outlined' style={{fontSize:'8pt',marginTop:'10px'}} onClick={(e) => setIsShowPassword(!isShowPassword)} >Keep Password</Button>}
                {(sk !=='0' && isShowPassword === false) && 
                      <Button style={{fontSize:'8pt'}} variant='outlined' onClick={(e) => setIsShowPassword(!isShowPassword)} >Change Password</Button>}
                </div>
                <div style={{marginBottom:'25px'}}>
                <label htmlFor="txtUserEmail" >Email</label>
                <Field
                  name="txtUserEmail"
                  id="txtUserEmail"
                  type="text"
                  value={userEmailValue}
                  onChange={(e) => setUserEmailValue(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
                </div>
                <div style={{marginBottom:'25px'}}>
                  <MySelect label="Neighborhood" name="neighborhoodSK"
                    id="neighborhoodCombo"
                    value={userNeighborhoodSK}
                    onChange={(e) => setUserNeighborhoodSK(e.target.value)} >
                    <option key="0" value="0">All Neighborhoods</option>
                    {(rsCombo) && rsCombo.map((option) => (
                      <option key={option.sk} value={option.sk}>
                        {option.fullName}
                      </option>))}
                  </MySelect>
                  </div>
                  <label style={{ marginBottom: "30px" }}>
                    <Field type="checkbox" name="chkIsUserAdministrator" checked={isUserAdministrator}
                      onChange={(e) => setIsUserAdministrator(!isUserAdministrator)} />
                    Administrator
                  </label>
                <div className='formButtonContainer'>
                  <LoadingButton type="submit" loading={isSubmitting} disabled={isSubmitting} variant='contained'>Save</LoadingButton>
                  <Button variant='outlined' onClick={() => clickCancel()} disabled={isSubmitting}>Cancel</Button>

                </div>
              </div></div>
          </Form>
        </Formik>
      }
      <Dialog
        open={openWarningDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogOK}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UserAddForm;